import { CustomRegExp, CustomValidationKeys } from 'store/models/paymentProcessModels';
import { defaultErrorsMap } from 'Modulor/fieldErrors';

const isValidExpiration = (value: string, pattern?: RegExp) => pattern?.test(value);

export const validateExpiration = (value: string, pattern?: CustomRegExp, validationKeys?: CustomValidationKeys) => {
  const [month, year] = value.split('/');

  if (pattern && (!isValidExpiration(month, pattern.month) || !isValidExpiration(year, pattern.year))) {
    return (!isValidExpiration(month, pattern.month) && validationKeys?.month) || (
      !isValidExpiration(month, pattern.year) && validationKeys?.year) || defaultErrorsMap.pattern;
  }

  if (Number(month) > 12) {
    return validationKeys?.month || defaultErrorsMap.pattern;
  }

  if (!pattern?.year) {
    const now = new Date();
    const expiration = new Date(2000 + Number(year), Number(month));
    const isValid = now.getTime() < expiration.getTime();
    if (!isValid) {
      return defaultErrorsMap.pattern;
    }
  }

  return true;
};
