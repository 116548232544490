import { PayHubCashierContractsViewDataResponse } from './APIModels';

export enum RenderCategory {
  depositMethodList,
  depositForm,
  withdrawalMethodList,
  withdrawalForm,
}

export interface Notification extends PayHubCashierContractsViewDataResponse {
  categories: RenderCategory[];
  removed: boolean;
  payGroupId?: number;
}

export type Notifications = Notification[];

export interface NotificationsState {
  notifications: Notifications;
}
