import { sortByArrangement, UIPaymentGroups } from 'store/formatters';
import { ALT_METHODS, SELECTED_METHOD_ID } from 'store/constants';
import { NATIVE_PAY_TYPES } from './constants';
import { softLocalStorageGet } from './storage';
import { stringToInt } from './dataTransform';

export const isNativePayAvailable = (
  nativePayType: string,
  isApplePayAvailable: boolean,
  isGooglePayAvailable: boolean,
): boolean => {
  if (nativePayType === NATIVE_PAY_TYPES.APPLE) {
    return isApplePayAvailable;
  }
  if (nativePayType === NATIVE_PAY_TYPES.GOOGLE) {
    return isGooglePayAvailable;
  }
  return false;
};

const filterByPayTypeAndRestrictions = (
  isApplePayAvailable: boolean,
  isGooglePayAvailable: boolean,
) => ({ nativePayType, enabled, restrictedUntil }: UIPaymentGroups) => {
  if (!enabled || restrictedUntil) {
    return false;
  }

  if (nativePayType) {
    return isNativePayAvailable(nativePayType, isApplePayAvailable, isGooglePayAvailable);
  }

  return true;
};

export const getMethodsToShow = (
  isApplePayAvailable: boolean,
  isGooglePayAvailable: boolean,
  methodsToFilter: UIPaymentGroups[],
): UIPaymentGroups[] => {
  const methods = methodsToFilter || softLocalStorageGet(ALT_METHODS);

  if (!methods?.length) {
    return [];
  }

  const selectedBeforeMethodId = stringToInt(localStorage.getItem(SELECTED_METHOD_ID) || '');
  let sorted: UIPaymentGroups[];

  try {
    sorted = (typeof methods === 'string' ? JSON.parse(methods) as UIPaymentGroups[] : methods)
      .filter(filterByPayTypeAndRestrictions(isApplePayAvailable, isGooglePayAvailable))
      .sort(sortByArrangement);
  } catch (e) {
    return [];
  }

  const inSorted:number | undefined = sorted.findIndex(({ id }) => id === selectedBeforeMethodId);

  if (inSorted !== -1) {
    sorted.splice(inSorted, 1);
  }

  return sorted.splice(0, 2);
};
