/* eslint-disable @typescript-eslint/no-empty-function */
import { Dispatch, FC, SetStateAction } from 'react';
import { Content, Image, ListCell, ListView, Radio, RadioGroup } from '@modulor/react';
import { CountryCode, countryDialCode } from 'assets/countryDialCode';

interface CountrySelectorProps {
  setCountryCode: (value: CountryCode) => void;
  countryCode: string;
  toggleModal: Dispatch<SetStateAction<boolean>>;
}

export const CountrySelector: FC<CountrySelectorProps> = ({ setCountryCode, countryCode, toggleModal }) => (
  <ListView>
    <RadioGroup onChange={() => {}}>
      {countryDialCode.map((country) => (
        <ListCell
          text={`+${country.phonecode} ${country.nicename}`}
          key={country.id}
          icon={(
            <Image
              name={country.iso}
              alt={country.nicename}
            />
          )}
          onClick={() => {
            setCountryCode(country);
            toggleModal(false);
          }}
          content={(
            <Content>
              <Radio
                value={country.iso}
                checked={country.iso === countryCode}
              />
            </Content>
          )}
        />
      ))}
    </RadioGroup>
  </ListView>
);
