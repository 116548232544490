import { ContentPageResponse } from '../store/models/contentPageAPI';
import { FlatStringObject } from '../commonTypes';

export const getContent = (
  data: ContentPageResponse,
  lang: string,
  brand: string,
) => {
  if (!data.length) {
    return '';
  }

  if (data.length === 1) {
    return data[0].content;
  }

  const parsedData: FlatStringObject = data.reduce((acc, current) => ({
    ...acc,
    [current.language.language_id]: current.content,
  }), {});

  const langWithBrand = brand ? [lang,
    brand].join('-') : lang;

  return parsedData[langWithBrand] || parsedData[lang] || parsedData.en;
};

export const formContent = (content: string, userId: string) => {
  if (!content?.length) {
    return '';
  }

  const replaceRegExp = /src="\/uploads\//gim;
  const replaceUserId = /\[user_account_id\]/g;

  const transformedContent = content.replace(replaceRegExp, 'src="/uploads/');

  if (userId) {
    return transformedContent.replace(replaceUserId, userId);
  }

  return transformedContent;
};
