import React, { FC, ReactElement } from 'react';
import { isMobile } from 'react-device-detect';
import { BottomSheet, Modal } from '@modulor/react';
import { IBottomSheetProps, IModalProps } from './types';
import { OPEN_BUTTON_SHEET, OPEN_MODAL } from './constants';

type IProps = {
    isPopupView?: boolean;
    className?: string;
} & (IModalProps | IBottomSheetProps);

const CustomModal: FC<IProps> = ({
  isPopupView = false,
  className,
  header,
  buttons,
  image,
  hero,
  title,
  description,
  vertical,
  dataTestId,
  children,
  ...restProps
}) => {
  const childrenArray = React.Children.toArray(children).filter(
    (child): child is ReactElement => React.isValidElement(child),
  );

  if (isMobile && isPopupView && OPEN_BUTTON_SHEET in restProps) {
    return (
      <BottomSheet
        minOpen={101}
        {...restProps}
        image={image}
        hero={hero}
        header={header}
        buttons={buttons}
        title={title}
        description={description}
        vertical={vertical}
        dataTestId={dataTestId}
      >
        {childrenArray}
      </BottomSheet>
    );
  }

  if (OPEN_MODAL in restProps) {
    return (
      <Modal
        {...restProps}
        image={image}
        hero={hero}
        header={header}
        buttons={buttons}
        title={title}
        description={description}
        vertical={vertical}
        dataTestId={dataTestId}
      >
        {childrenArray}
      </Modal>
    );
  }

  return null;
};

export default CustomModal;
