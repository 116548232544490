export const softLocalStorageSet = (key: string | undefined | null, value: string): void => {
  if (!key) return;

  try {
    localStorage.setItem(key, value);
    // eslint-disable-next-line no-empty
  } catch {}
};

export const softLocalStorageGet = (key?: string): string | null | undefined => {
  if (!key) return null;

  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
};

export const softLocalStorageRemove = (key?: string): void => {
  if (!key) return;

  try {
    localStorage.removeItem(key);
    // eslint-disable-next-line no-empty
  } catch {}
};

export const softSessionStorageSet = (key: string | undefined | null, value: string): void => {
  if (!key) return;

  try {
    sessionStorage.setItem(key, value);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export const softSessionStorageGet = (key?: string): string | null | undefined => {
  if (!key) return null;

  try {
    return sessionStorage.getItem(key);
  } catch (error) {
    return null;
  }
};

export const softSessionStorageRemove = (key?: string): void => {
  if (!key) return;

  try {
    sessionStorage.removeItem(key);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};
