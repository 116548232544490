import { FC } from 'react';
import { Typography } from '@modulor/react';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';
import './ProcessingTime.scss';
import { getTranslationMessage } from '../../utils';

interface ProcessingTimeProps {
  message?: string;
}

export const ProcessingTime: FC<ProcessingTimeProps> = (props) => {
  const { message } = props;
  const { t } = useTranslation();
  const messageText = getTranslationMessage({
    t,
    value: message,
  });

  if (!message) {
    return null;
  }

  return (
    <div className="processing-time-container">
      <Typography variant="subhead-regular">
        {
          t(`${NAMESPACES.PAYGOUPS_AND_PROCESSING_TIME}:PH.PROCESSING_TIME`)
        }
      </Typography>
      <Typography variant="subhead-regular">{messageText}</Typography>
    </div>
  );
};
