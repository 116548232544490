import React from 'react';
import { Button } from '@modulor/react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../services/constants';

interface LoadButtonProps {
    Uploader: React.ReactNode;
    isLoading: boolean;
    handleUploadFile: () => void;
}

const LoadButton: React.FC<LoadButtonProps> = ({ Uploader, isLoading, handleUploadFile }) => {
  const { t } = useTranslation();
  return (
    <div>
      {Uploader}
      <Button
        variant="secondary"
        size="low"
        loading={isLoading}
        icon="plus"
        onClick={handleUploadFile}
      >
        {t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.UPLOAD_DOC.BUTTON_ADD_FILES`)}
      </Button>
    </div>
  );
};

export default LoadButton;
