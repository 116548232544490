/* eslint-disable camelcase */
import {
  PayHubCashierContractsCashierLLAResultResponse,
  PayHubCashierContractsEnumsCashierActionType,
  PayHubWidgetContractsSubmitPaymentResponse,
} from '../models';

export const formatInteraction = (
  response: PayHubCashierContractsCashierLLAResultResponse,
): PayHubWidgetContractsSubmitPaymentResponse => {
  const {
    action = PayHubCashierContractsEnumsCashierActionType.Redirect,
    redirect_data,
  } = response;
  const { url, method, fields } = redirect_data;

  return {
    action,
    request_method: method,
    request_url: url,
    request_fields: fields,
  };
};
