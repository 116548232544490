import { FC, useEffect, useState } from 'react';
import { Control, Controller, FieldValues, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
// eslint-disable-next-line max-len
import {
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType as Type,
} from 'store/models/APIModels';
import { useAppDispatch } from 'store/hooks';
import { TextField, Typography } from '@modulor/react';
import { setCardFieldName } from 'store/slices/checkout';
import { FieldType, getPattern, getTranslationMessage } from 'utils';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from 'services/constants';
import { getErrorKey } from '../fieldErrors';
import { formatCardNumber, getAdditionalRules, MAX_CARD_LENGTH_WITH_SPACES } from './utils';

interface CardFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  regexp?: string;
  isRequired?: boolean;
  extendedType?: ExtendedTypes;
  placeHolder?: string;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  verifyNewPayoutAccount?: boolean;
  type: string;
  validationMsgKey?: string;
}

export const CardField:FC<CardFieldProps> = (props) => {
  const {
    name,
    label,
    placeHolder = '',
    control,
    isRequired,
    type,
    extendedType,
    setError,
    clearErrors,
    verifyNewPayoutAccount,
    validationMsgKey,
    regexp,
  } = props;

  const isEmptyListError = (
    verifyNewPayoutAccount === undefined || type === Type.Input
  ) ? true : verifyNewPayoutAccount;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const pattern = getPattern(regexp, extendedType);
  const additionalParams = getAdditionalRules({
    extendedType,
    setError,
    clearErrors,
    pattern,
  });
  let isDisabledProp = false;

  useEffect(() => {
    if (!isEmptyListError) {
      setError(name || '', {
        type: 'manual',
      });
    }
  }, [isEmptyListError]);

  useEffect(() => {
    // TODO: find more elegant suggestion
    dispatch(setCardFieldName(name));
  }, []);

  const [cardValue, setCardValue] = useState('');
  const handleOnChange = (value: string, field: FieldValues) => {
    setCardValue(value);
    field.onChange(formatCardNumber(value)?.replace(/\s/g, ''));
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
        pattern,
        ...additionalParams,
      }}
      render={({ field, fieldState }: FieldValues) => {
        const errorMessageKey = getErrorKey(FieldType.cardPan, fieldState, validationMsgKey);
        const errorMessage = getTranslationMessage({
          t,
          value: errorMessageKey,
        });

        if (!isEmptyListError) {
          isDisabledProp = true;
        }

        return (
          <>
            <TextField
              {...field}
              maxLength={MAX_CARD_LENGTH_WITH_SPACES}
              name={name}
              placeholder={placeHolder}
              error={errorMessage}
              label={label}
              value={formatCardNumber(cardValue)}
              onChange={(value: string) => handleOnChange(value, field)}
              disabled={isDisabledProp}
              readOnly={isDisabledProp}
            />
            {!isEmptyListError && (
              <Typography
                variant="subhead-regular"
                tag="span"
                style={{
                  color: 'var(--text-error)',
                  padding: '4px 16px',
                  fontSize: '13px',
                  letterSpacing: '-0.3px',
                  marginTop: '-6px',
                  marginBottom: '8px',
                  display: 'flex',
                }}
              >
                {t(`${NAMESPACES.PW_KEYS}:CASHIER.PH2.PLACEHOLDER.EMPTYLIST`)}
              </Typography>
            )}
          </>
        );
      }}
    />
  );
};
