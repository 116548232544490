/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import { MouseEvent } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { UIPaymentGroups, UIPayout, UIPayoutGroups } from 'store/formatters';
import { setSelectedPayGroupId } from 'store/slices/global';
import {
  CurrencyDisplaySetting,
  Limits,
  MerchantUserFeeResponce,
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType,
  PayHubDomainSessionsEnumsPlatformType, ProcessingCurrency,
} from 'store/models';
import { NavigateFunction } from 'react-router-dom';
import { formatAmount, getFormattedAmountWithCurrency, getPrettierFormatNumber, SelectActionWithdrawalMethodProps, softLocalStorageSet } from 'utils';
import { setCurrentMethod } from 'store/slices/withdrawal';
import { FlowTypes, SELECTED_METHOD_ID } from 'store/constants';
import { redirectToSelectedMethod } from '../routerUtils';
import { Amount, getConversionText } from '../Deposit/utils';
import { TFunction } from '../../commonTypes';
import { NAMESPACES } from '../../services/constants';

export const selectActionMethod = (props: SelectActionWithdrawalMethodProps): void => {
  const {
    method,
    navigate,
    dispatch,
    flowType,
  } = props;
  dispatch(setCurrentMethod(method));
  redirectToSelectedMethod(method.id, navigate, flowType);
};

interface SelectWithdrawalMethodHandlerProps {
  method: UIPaymentGroups | UIPayoutGroups;
  platform?: PayHubDomainSessionsEnumsPlatformType;
  dispatch: Dispatch;
  navigate: NavigateFunction;
  flowType: FlowTypes;
}

const METHODS_WITH_FORM: string[] = [
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType.Activity,
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType.NestedList,
];

export const selectWithdrawalMethodHandler = (props: SelectWithdrawalMethodHandlerProps, event?: MouseEvent): boolean | undefined => {
  const {
    method,
    dispatch,
    navigate,
    flowType,
  } = props;

  if (!method.enabled) {
    event?.stopPropagation();
    event?.preventDefault();
    return false;
  }

  dispatch(setSelectedPayGroupId(method.id));
  softLocalStorageSet(SELECTED_METHOD_ID, JSON.stringify(method.id));

  if (METHODS_WITH_FORM.includes(method.payGroupActionType)) {
    selectActionMethod({
      method,
      dispatch,
      navigate,
      flowType,
    });
  }

  return undefined;
};

export const getMaxLimit = (limits: Limits | null, userBalance?: number): number | undefined => {
  if (userBalance && userBalance > (limits?.min || 0)) {
    return ((limits?.max && limits?.max > userBalance) || !limits?.max) ? userBalance : limits?.max;
  }

  return limits?.max || undefined;
};

interface WithdrawalSubmitTextProps {
  t: TFunction;
  amount: Amount;
  currency: string;
  taxAmount?: number | string;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  currencyDisplaySetting?: CurrencyDisplaySetting;
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string | null;
}

interface GetFormattedFeeSubTextProps {
  amount: Amount;
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string | null;
}

const getFeeAmountSubtext = ({ amount, merchantUserFee, fee }: GetFormattedFeeSubTextProps) => {
  const amountAsNumber = Number(amount);
  const feeAsNumber = typeof fee === 'string' ? Number(fee.replace('%', '')) : 0;
  const percentageFeeAmountMerchant = merchantUserFee?.percentage
    ? (amountAsNumber * (merchantUserFee.percentage / 100))
    : 0;
  const percentageFeeAmount = feeAsNumber ? (amountAsNumber * (feeAsNumber / 100)) : 0;
  const fixedFeeAmount = merchantUserFee?.fix || 0;
  return (percentageFeeAmountMerchant || percentageFeeAmount) + fixedFeeAmount;
};

export const getWithdrawalSubmitSubText = (props: WithdrawalSubmitTextProps) => {
  const {
    t,
    amount,
    currency,
    taxAmount,
    processingCurrency,
    processingCurrencyRate,
    currencyDisplaySetting,
    merchantUserFee,
    fee,
  } = props;
  const messages: string[] = [];

  if (processingCurrency && processingCurrencyRate) {
    messages.push(getConversionText({
      amount, processingCurrency, processingCurrencyRate, currencyDisplaySetting,
    }));
  }

  if (taxAmount) {
    const translation = t(`${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.TOOLTIP.TAX.TITLE`);
    messages.push(`${translation} ${getFormattedAmountWithCurrency(
      getPrettierFormatNumber(taxAmount),
      currency,
      currencyDisplaySetting,
    )}`);
  }

  if ((merchantUserFee || fee) && getFeeAmountSubtext({
    amount, merchantUserFee, fee,
  })) {
    const feeText = t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.LABEL.FEE`);
    messages.push(`${feeText} ${formatAmount(getFeeAmountSubtext({
      amount, merchantUserFee, fee,
    }))}${currency}`);
  }

  return messages.length ? messages.join('; ') : '';
};

interface SetWithdrawalInitDataToStoreProps {
  initData: UIPayout;
  dispatch: Dispatch;
  navigate: NavigateFunction;
  payGroupId?: number;
}

export const setWithdrawalInitDataToStore = (props: SetWithdrawalInitDataToStoreProps) => {
  const {
    initData,
    dispatch,
    navigate,
    payGroupId,
  } = props;

  if (!payGroupId) {
    return;
  }

  const method = initData.methods.find(({ id }) => id === payGroupId);

  if (!method) {
    return;
  }

  selectWithdrawalMethodHandler({
    method,
    platform: initData?.widgetSettings?.platform,
    dispatch,
    navigate,
    flowType: FlowTypes.withdrawal,
  }, {} as MouseEvent);
};
