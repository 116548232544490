import { createElement } from 'react';
import { Icon } from '@modulor/react';
import creditCardType from 'credit-card-type';
import MastercardIcon from 'assets/MastercardIcon';
import VisaIcon from 'assets/VisaIcon';
import AmericanExpressIcon from 'assets/anex';
import UnionPayIcon from 'assets/UnionPayIcon';
import MaestroIcon from 'assets/MaestroIcon';

type IconType = {
  [key: string]: () => JSX.Element;
};

// TODO: add maestro icon
export const CARDS_ICON_TYPE_MAP: IconType = {
  'american-express': AmericanExpressIcon,
  maestro: MaestroIcon,
  mastercard: MastercardIcon,
  unionpay: UnionPayIcon,
  visa: VisaIcon,
};

export const getCardsPlaceholder = (last4?: string | null, bin?: string | null) => {
  const first4 = bin?.substring(0, 4) || '****';
  const second2 = bin?.substring(4, 6) || '**';

  return `${first4} ${second2}** **** ${last4}`;
};

export const getCardsIcon = (digits: string | null | undefined) => {
  if (digits && !!creditCardType(digits).length) {
    const detectedType = creditCardType(digits)[0].type;
    return detectedType && CARDS_ICON_TYPE_MAP[detectedType]
      ? createElement(CARDS_ICON_TYPE_MAP[detectedType])
      : <Icon name="credit_card_outlined" />;
  }
  return <Icon name="credit_card_outlined" />;
};

export const getCardsTypeName = (digits: string | null | undefined) => (
  digits && creditCardType(digits)?.length ? creditCardType(digits)[0].niceType : 'Card');
