/* eslint-disable no-restricted-globals, camelcase */
import { omit } from 'ramda';
import {
  PayHubCashierContractsCashierBrowserDataRequest,
  PayHubCashierContractsPaymentsSubmitPaymentGroupRequest as SubmitPaymentGroupReq,
  PayHubCashierContractsPayoutsSubmitPayoutGroupRequest as SubmitPayoutGroupReq,
} from 'store/models';
import { FINGERPRINT } from 'store/constants';
import { softLocalStorageGet } from 'utils';

interface FormRequestBodyProps {
  fields: Record<string, string | number | unknown>;
  currency: string;
  amount: string;
  isNewPayoutAccount?: boolean;
}

interface OptionProps {
  [x: string]: unknown;
  name?: string;
  label?: string;
  value: string;
}

export const getBrowserData = (): PayHubCashierContractsCashierBrowserDataRequest => {
  const currentTimeZoneInMinutesToString = String(new Date().getTimezoneOffset());
  const browserLanguage = window.navigator.language.substr(0, 2).toLowerCase();
  const colorDepth = String(screen.colorDepth ? screen.colorDepth : screen.pixelDepth);
  const isJavaEnabled = String(window.navigator.javaEnabled());
  const { userAgent } = navigator;
  let timezoneName;
  try {
    timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // empty
  }

  return ({
    color_depth: colorDepth,
    language: browserLanguage,
    time_zone: currentTimeZoneInMinutesToString,
    timezone_name: timezoneName || 'undefined',
    java_enabled: isJavaEnabled,
    screen_height: String(screen.height),
    screen_width: String(screen.width),
    window_height: String(window.innerHeight),
    window_width: String(window.innerWidth),
    user_agent: userAgent,
  });
};

const prepareFieldsDataToServer = (fields: Record<string, string | number | unknown | OptionProps>) => {
  const result: Record<string, string | number | unknown> = {};
  Object.keys(fields).forEach((item) => {
    const fieldValue: string | number | unknown | OptionProps = fields[item];
    if (fieldValue && typeof fieldValue === 'object' && 'value' in fieldValue) {
      result[item] = fieldValue.value;
    } else {
      result[item] = fieldValue;
    }
  });

  return result;
};

export const formRequestBody = (
  props:FormRequestBodyProps,
): (SubmitPaymentGroupReq & SubmitPayoutGroupReq) => {
  const {
    fields,
    currency,
    amount,
    isNewPayoutAccount = false,
  } = props;
  const amountValue = parseFloat(amount);
  const browser_data = getBrowserData();
  const fingerprint = softLocalStorageGet(FINGERPRINT);
  const fieldsData = prepareFieldsDataToServer(fields);

  return {
    amount: amountValue,
    currency,
    browser_data,
    parameters: omit(['amount'], fieldsData),
    fingerprint,
    is_new_payout_account: isNewPayoutAccount,
  };
};
