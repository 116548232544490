import { Control, FieldValues, UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import {
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedType,
} from 'store/models';
import { fieldGetter } from '../fieldGetter';
import { TFunction } from '../../commonTypes';

interface RenderFields {
  setError: UseFormSetError<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  fields: Parameter[];
  control: Control;
  t: TFunction;
}

export const renderFields = (props: RenderFields) => {
  const {
    setError,
    setValue,
    clearErrors,
    fields,
    control,
    t,
  } = props;

  return fields.map((field: Parameter) => {
    if (field.name === 'pair' && field.items?.length) {
      return (
        <div
          key={field.name}
          className="pair-row"
        >
          {renderFields({
            t,
            setError,
            setValue,
            clearErrors,
            fields: field.items,
            control,
          })}
        </div>
      );
    }
    return fieldGetter({
      t,
      setError,
      setValue,
      clearErrors,
      formField: {
        control,
      },
      isCheckout: true,
    })(field);
  });
};

export const getIsCVVFieldRequired = ({
  methodParams,
  isCardTokenIsActive,
  amount,
  maxRecurrentAmount,
}: {
  methodParams: Parameter[];
  isCardTokenIsActive: boolean;
  amount: number;
  maxRecurrentAmount?: number;
}): boolean => {
  const cvvField = methodParams?.find(
    (param) => param?.type === 'input' && param?.extendedType === ExtendedType.CardCvv,
  );

  // BILL-4220
  if (!cvvField) {
    return false;
  }

  if (!isCardTokenIsActive) {
    const selectedCvvField = cvvField || {
      isRequired: true,
    };
    const isAmountMoreThanMax = maxRecurrentAmount && amount > maxRecurrentAmount;

    return Boolean(isAmountMoreThanMax || selectedCvvField?.isRequired);
  }

  return true;
};

export const prepareRequiredFields = (fields: Parameter[]) => {
  const expirationDateField = fields.find((field: Parameter) => field.name === 'pair')?.items?.find((obj) => (
    obj.name === 'expirationDate')) || null;
  return expirationDateField === null ? fields : [
    ...fields,
    expirationDateField];
};

export const isShowPCIDSSLogoByBrand = (brand: string) => !['brand32'].includes(brand);
