export const expandedVisaCard = [{
  // NOTE: due to BILL-2181
  niceType: 'Visa',
  type: 'visa',
  patterns: [4],
  gaps: [
    4,
    8,
    12,
  ],
  lengths: [
    16,
    17,
    18,
    19,
  ],
  code: {
    name: 'CVV',
    size: 3,
  },
}];

export const exoticCards = [{
  // NOTE: due to PCIDSS-683
  niceType: 'UZS',
  type: 'UZS',
  patterns: [8],
  gaps: [
    4,
    8,
    12,
  ],
  lengths: [16],
  code: {
    name: 'CVV',
    size: 3,
  },
},
{
  // NOTE: due to BILL-2825
  niceType: 'UZCARD',
  type: 'UZCARD',
  patterns: [9],
  gaps: [
    4,
    8,
    12,
  ],
  lengths: [16],
  code: {
    name: 'CVV',
    size: 3,
  },
},
{
  // NOTE: due to BILL-2825
  niceType: 'HUMO',
  type: 'HUMO',
  patterns: [6],
  gaps: [
    4,
    8,
    12,
  ],
  lengths: [16],
  code: {
    name: 'CVV',
    size: 3,
  },
}];
