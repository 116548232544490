import { FlatStringObject } from 'commonTypes';

export default class IntegrationCommunication {
  static sendMessage(msg: FlatStringObject | Record<string, unknown>) {
    const message = {
      target: 'cashier',
      ...msg,
    };

    window.parent.postMessage(JSON.stringify(message), '*');
  }
}
