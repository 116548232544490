/* eslint-disable camelcase */
import { Dispatch } from '@reduxjs/toolkit';
import {
  PayHubCashierContractsPaymentSessionStateResponse,
  PayHubCashierContractsEnumsSubmitPaymentStatus as PaymentStatus,
} from 'store/models';
import { setPaySessionState } from 'store/slices/paySessionState';

interface CreatorProps {
  dispatch: Dispatch;
}

export const paySessionStateSubmitFormHandlerCreator = (
  creatorProps: CreatorProps,
) => async (
  json: string,
  stopConnection: () => Promise<void>,
) => {
  const {
    dispatch,
  } = creatorProps;
  const data = JSON.parse(json) as PayHubCashierContractsPaymentSessionStateResponse;

  if (data.error_fatal) {
    await stopConnection();
    return;
  }

  const isResultStatus = data.action === 'result' && [
    PaymentStatus.Success,
    PaymentStatus.Fail,
  ].includes(data.result_status as PaymentStatus);

  if (data.session_id) {
    const {
      session_id,
      success_url,
      fail_url,
      processing_url,
      merchant_url,
      redirect_timeout,
    } = data;
    const returnUrls = {
      session_id,
      success_url,
      fail_url,
      processing_url,
      merchant_url,
      redirect_timeout,
    };
    dispatch(setPaySessionState(returnUrls));
  }

  if (data.session_id && isResultStatus) {
    dispatch(setPaySessionState(data));
    await stopConnection();
  }
};
