/* eslint-disable camelcase */
import qs from 'query-string';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  PayHubCashierContractsSendOTPResponse,
  PayHubCashierContractsVerifyOTPResponse as VerifyCodeResponse,
  PayHubCashierContractsCreateRequisiteRequest as CreateRequisiteRequest,
  PayHubCashierContractsCreateRequisiteResponse,
} from 'store/models';
import { RootState } from 'store';
import { WIDGET_API } from 'utils/constants';
import { setReqHeaders } from 'utils/common';

type VerifyCodeRequestProps = {
  contactType: string;
};

export interface SendCodeResponse {
  success?: boolean;
  maxRequestCount?: number;
  blockDuration?: string;
}

export interface CreateRequisiteResponse {
  requisiteId?: number | null;
}

export const accountManagementAPI = createApi({
  reducerPath: 'accountManagementAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: WIDGET_API,
    prepareHeaders(headers, { getState }) {
      const { session_id = '' } = qs.parse(window?.location?.search || '');
      const { sessionId } = (getState() as RootState).common;
      setReqHeaders(headers, sessionId || `${session_id}`);
    },
  }),
  endpoints(builder) {
    return {
      createRequisite: builder.query<CreateRequisiteResponse, CreateRequisiteRequest>({
        query: (body) => ({
          url: '/requisite/create',
          method: 'POST',
          body,
        }),
        transformResponse: (
          response: PayHubCashierContractsCreateRequisiteResponse,
        ): CreateRequisiteResponse => ({
          requisiteId: response.requisite_id,
        }),
      }),
      sendCode: builder.query<
        SendCodeResponse,
          VerifyCodeRequestProps
          >({
            query: ({ contactType }) => ({
              url: '/otp/send',
              method: 'POST',
              body: {
                contact_type: contactType,
              },
            }),
            transformResponse: (
              response: PayHubCashierContractsSendOTPResponse,
            ): SendCodeResponse => ({
              success: response.success,
              maxRequestCount: response.max_request_count || undefined,
              blockDuration: response.block_duration || undefined,
            }),
          }),
      verifyCode: builder.mutation<VerifyCodeResponse, string>({
        query: (otp) => ({
          url: '/otp/verify',
          method: 'POST',
          body: {
            otp,
          },
        }),
      }),
    };
  },
});

export const {
  useLazyCreateRequisiteQuery,
  useLazySendCodeQuery,
  useVerifyCodeMutation,
} = accountManagementAPI;
