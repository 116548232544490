/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useEffect, useState } from 'react';
import IntegrationCommunication from 'services/integration-communication';
import { useLazySubmitDepositQuery } from 'store/services/depositAPI';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  PaymentProcessMethodInfo,
  Limits,
  PayHubCashierContractsViewDataResponse,
  MerchantUserFeeResponce, ProcessingCurrency,
  PayHubCashierContractsPaymentsSelectPaymentGroupResponse, PayHubWidgetContractsUserCardResponse, Parameter,
} from 'store/models';
import {
  getErrorMessage, getTranslationMessage,
  prepareParametersForSubmit,
} from 'utils';
import { FieldValues } from 'react-hook-form';
import { Icon, Toast } from '@modulor/react';
import { setSessionExpired } from 'store/slices/global';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamPayGroupCashierContext,
  getClickStreamEvent,
} from 'services/clickstream';
import { CustomServerError, useLazyGetUserCardsQuery, useLazyRecurringCheckQuery } from 'store/services/checkoutAPI';
import { selectedCardSelector, setCards, setSelectedCard } from 'store/slices/userCard';
import { SubmitFormWrapper } from 'Modulor/components/SubmitForm/SubmitFormWrapper';
import { FlowTypes } from 'store/constants';
import ModulorLoader from 'Modulor/components/ModulorLoader';
import { methodNameSelector } from 'store/slices/deposit';
import { isCarouselViewSelector } from 'store/slices/carousel';
import { useTranslation } from 'react-i18next';
import { CheckoutForm } from './CheckoutForm';
import { useAbortSubmitControllerRef, useFrameChangeHeight } from '../hooks';
import { getIsCVVFieldRequired } from './utils';
import { SubmitHandler } from '../SubmitStrategy/SubmitHandler';
import { PaymentProcessStrategy } from '../SubmitStrategy/PaymentProcessStrategy';

import './Checkout.scss';

interface CheckoutControllerProps {
  amount: number;
  setIsCheckoutFormSubmitted?: (flag: boolean) => void;
  withAmount?: boolean;
  amountRegExpFe?: string;
  limits?: Limits | null;
  currency: string;
  presetCurrency?: string | null;
  payGroupId: number;
  methodInfo: PayHubCashierContractsPaymentsSelectPaymentGroupResponse;
  isMethodLoadSuccess: boolean;
  additionalViewData?: PayHubCashierContractsViewDataResponse[] | null;
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string | null;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  formConfig: Parameter[];
}

export const CheckoutController: FC<CheckoutControllerProps> = ({
  amount,
  setIsCheckoutFormSubmitted = () => {},
  withAmount = false,
  amountRegExpFe,
  limits = null,
  currency,
  presetCurrency,
  payGroupId,
  methodInfo = {},
  isMethodLoadSuccess,
  additionalViewData,
  merchantUserFee,
  fee,
  processingCurrency,
  processingCurrencyRate,
  formConfig,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const methodName = useAppSelector(methodNameSelector);
  const { selectedCard, cardsList } = useAppSelector(selectedCardSelector);
  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(FlowTypes.deposit);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const [submitGeneralError, setSubmitGeneralError] = useState<string>('');
  const submitHandler = new SubmitHandler(new PaymentProcessStrategy());
  const {
    requiredFields: methodParameters,
    amountBlocks,
  } = methodInfo as PaymentProcessMethodInfo || {};
  const { maxRecurrentAmount = 0 } = limits || {};
  const isCarouselView = useAppSelector(isCarouselViewSelector);

  const [getCardListTrigger, cardListResult] = useLazyGetUserCardsQuery();
  const [recurringCheckTrigger, resultRecurringCheck] = useLazyRecurringCheckQuery();
  const [trigger, result] = useLazySubmitDepositQuery();
  const abortControllerRef = useAbortSubmitControllerRef();
  const lastUsedCard = cardListResult.data?.cards?.find((
    item: PayHubWidgetContractsUserCardResponse,
  ) => item.is_last_used);

  const {
    error,
    data,
    isFetching: isFetchingSubmitRequest,
  } = result;

  const localCurrentIsCVVPresent = getIsCVVFieldRequired({
    methodParams: methodParameters,
    isCardTokenIsActive: !!selectedCard?.is_card_token_active,
    amount,
    maxRecurrentAmount,
  });

  useEffect(() => {
    getCardListTrigger();
  }, []);

  useEffect(() => {
    if (cardListResult.data?.cards?.length && cardListResult.isSuccess && !cardListResult.isFetching) {
      dispatch(setCards(cardListResult.data.cards));
      if (lastUsedCard?.id && !selectedCard?.id && !selectedCard.isNew) {
        dispatch(setSelectedCard(lastUsedCard));
      }
    }
  }, [
    cardListResult.isFetching,
    lastUsedCard,
  ]);

  useEffect(() => {
    if (error && (error as CustomServerError)?.errorCode === 17) {
      dispatch(setSessionExpired());
      return;
    }

    if (error) {
      const errorObj = getErrorMessage(error);
      const { message = '' } = errorObj;
      const messageText = getTranslationMessage({
        t,
        value: message,
      });

      setSubmitGeneralError(messageText);
    }
  }, [error]);

  useEffect(() => {
    if (!submitGeneralError && data) {
      setIsCheckoutFormSubmitted(true);
    }
  }, [
    submitGeneralError,
    data,
  ]);

  useFrameChangeHeight(isMethodLoadSuccess && !cardListResult.isLoading);

  if (!submitGeneralError && data) {
    return (
      <SubmitFormWrapper
        paymentType={FlowTypes.deposit}
        redirectForm={data}
      />
    );
  }

  if (cardListResult.isFetching) {
    return <ModulorLoader />;
  }

  const submitCallback = (fields: FieldValues, isNew: boolean) => {
    if (isCarouselView) {
      const event = getClickStreamEvent.demetr_carousel_submit_click;
      clickstream.push(event, [cashierContext,
        payGroupContext]);
    }

    const clEvent = getClickStreamEvent.cashier_method_submit_button_click;
    clickstream.push(clEvent, [cashierContext,
      payGroupContext]);
    const fieldsForRequest = prepareParametersForSubmit(fields, {
      isCvvPresent: localCurrentIsCVVPresent || resultRecurringCheck.data?.is_cvv_required,
      isCardTokenActive: isNew && !!selectedCard?.is_card_token_active,
      isCheckout: true,
    }, isNew);
    const amountFieldValue = fieldsForRequest?.amount || amount;
    IntegrationCommunication.sendMessage({
      id: `${FlowTypes.deposit}Submit`,
      value: {
        amount: amountFieldValue,
        currency,
      },
    });
    abortControllerRef.current = new AbortController();

    submitHandler.handle({
      payGroupId,
      fields: fieldsForRequest,
      currency,
      amount: amountFieldValue,
      trigger,
      signal: abortControllerRef.current.signal,
    });
  };

  return (
    <div className="checkout-form-wrapper">
      <CheckoutForm
        amount={amount}
        localCurrentIsCVVPresent={localCurrentIsCVVPresent}
        methodParameters={methodParameters}
        submitCallback={submitCallback}
        cardsList={cardsList}
        amountBlocks={amountBlocks}
        currency={currency}
        withAmount={withAmount}
        methodName={methodName}
        amountRegExpFe={amountRegExpFe}
        limits={limits}
        isFetchingSubmitRequest={isFetchingSubmitRequest}
        presetCurrency={presetCurrency}
        additionalViewData={additionalViewData}
        merchantUserFee={merchantUserFee}
        fee={fee}
        recurringCheckTrigger={recurringCheckTrigger}
        shouldVerifyCVV={resultRecurringCheck.data?.is_cvv_required}
        processingCurrency={processingCurrency}
        processingCurrencyRate={processingCurrencyRate}
        payGroupId={payGroupId}
        formConfig={formConfig}
      />
      {submitGeneralError && (
        <Toast
          variant="error"
          visible={Boolean(submitGeneralError)}
          text={t(submitGeneralError)}
          multiline
          rightIcon={(
            <Icon
              name="close"
              onClick={
                () => setSubmitGeneralError('')
              }
            />
          )}
          onHide={() => setSubmitGeneralError('')}
          duration={2500}
        />
      )}
    </div>
  );
};
