/* eslint-disable camelcase */
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { RootState } from 'store';
import {
  PayHubCashierContractsPayoutsInitPayoutGroupsResponse as InitSuccess,
  PayHubCashierContractsPaymentsSelectPaymentGroupResponse as DepositMethodInfoResponse,
  PayHubCashierContractsPaymentsSubmitPaymentGroupRequest,
  PayHubWidgetContractsSubmitPaymentResponse,
  PayHubWidgetContractsSubmitPayoutResponse,
} from 'store/models';
import { formDepositGroups, formDepositRequiredFields, formDepositMethodInfo, UIPayment } from 'store/formatters';
import { setReqHeaders } from 'utils/common';
import qs from 'query-string';

interface UploadDocFileRequest {
  payGroupId: number;
  requestBody: FormData;
  signal?: AbortSignal;
}

type UploadDocFileResponse = void;

export interface CustomServerError {
  status: Pick<FetchBaseQueryError, 'status'> | string | number;
  errorMessage?: string;
  errorCode?: number;
  error_code?: number;
}

type ExtendedFetchBaseQueryError = FetchBaseQueryError & {
  data?: {
    error_message: string;
    error_code: number;
  };
};

export interface DepositSubmitRequestProps {
  payGroupId: number;
  requestBody: PayHubCashierContractsPaymentsSubmitPaymentGroupRequest;
  signal: AbortSignal;
}

export type GetDepositMethodInfoRequestProps = {
  payGroupId: number;
  currency: string;
};

const CASHIER_SERVICE_ENV = process.env.NODE_ENV === 'production' ? '/api/cashier-service/' : '/cash-ph20-service/';
const CASHIER_API = `${CASHIER_SERVICE_ENV}v1.0`;

export const depositAPI = createApi({
  reducerPath: 'depositAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: CASHIER_API,
    prepareHeaders(headers, { getState }) {
      const { session_id = '' } = qs.parse(window?.location?.search || '');
      const { sessionId } = (getState() as RootState).common;
      setReqHeaders(headers, sessionId || `${session_id}`);
    },
  }),
  endpoints(builder) {
    return {
      getDepositMethods: builder.query<UIPayment, void>({
        query: () => ({
          url: '/payments/groups/init',
        }),
        keepUnusedDataFor: Infinity,
        transformResponse: (response: InitSuccess) => formDepositGroups(response),
      }),
    };
  },
});

export const extendedDepositAPI = depositAPI.injectEndpoints({
  endpoints: (builder) => ({
    getDepositMethodInfo: builder.query<
      DepositMethodInfoResponse,
      GetDepositMethodInfoRequestProps
      >({
        query: ({ payGroupId, currency }) => ({
          url: `/payments/groups/${payGroupId}/select`,
          method: 'POST',
          body: {
            currency,
          },
        }),
        keepUnusedDataFor: Infinity,
        transformResponse: (
          response: DepositMethodInfoResponse,
          meta,
          { payGroupId },
        ) => formDepositMethodInfo(response, formDepositRequiredFields, payGroupId) as DepositMethodInfoResponse,
      }),
    submitDeposit: builder.query<
      PayHubWidgetContractsSubmitPaymentResponse
      | PayHubWidgetContractsSubmitPayoutResponse,
      DepositSubmitRequestProps
    >({
      query: ({
        payGroupId,
        requestBody,
        signal,
      }) => ({
        url: `payments/groups/${payGroupId}/submit`,
        method: 'POST',
        body: requestBody,
        signal,
      }),
      transformErrorResponse: (response: ExtendedFetchBaseQueryError): CustomServerError => ({
        status: response?.status,
        errorMessage: response?.data?.error_message,
        errorCode: response?.data?.error_code,
      }),
    }),
    uploadDocFileForDeposit: builder.query<UploadDocFileResponse, UploadDocFileRequest>({
      query: ({
        payGroupId,
        requestBody: formData,
        signal,
      }) => ({
        url: `payments/groups/${payGroupId}/upload`,
        method: 'POST',
        body: formData,
        signal,
      }),
      transformErrorResponse: (response: ExtendedFetchBaseQueryError): CustomServerError => ({
        status: response?.status,
        errorMessage: response?.data?.error_message,
        errorCode: response?.data?.error_code,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDepositMethodsQuery,
  useLazyGetDepositMethodsQuery,
} = depositAPI;
export const {
  useGetDepositMethodInfoQuery,
  useLazyGetDepositMethodInfoQuery,
  useLazySubmitDepositQuery,
  useLazyUploadDocFileForDepositQuery,
} = extendedDepositAPI;

export const { endpoints } = depositAPI;

export default depositAPI.reducer;
