import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { TextField } from '@modulor/react';
import { FieldType, stringToInt } from 'utils';
import { applyDateMask } from 'store/formatters/global';
import { getErrorKey } from '../../fieldErrors';
import { isValidDate } from './utils';
import { DEFAULT_DATE_FORMAT } from './constants';

interface DateInputFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  isRequired?: boolean;
  placeHolder?: string;
  defaultValue: string | null;
  isDisabled?: boolean;
  validationMsgKey?: string;
}

export const DateInputField: FC<DateInputFieldProps> = (props) => {
  const {
    name,
    label,
    placeHolder = DEFAULT_DATE_FORMAT,
    control,
    isRequired,
    defaultValue,
    isDisabled = false,
    validationMsgKey,
  } = props;
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const date = defaultValue && new Date(defaultValue);
    if (date && !Number.isNaN(date.getTime())) {
      setInputValue(dayjs(date).format(DEFAULT_DATE_FORMAT));
    }
  }, [defaultValue]);

  const handleChange = (value: string, field: FieldValues) => {
    const maskedValue = applyDateMask(value);
    setInputValue(maskedValue);

    const [dayStr, monthStr, yearStr] = maskedValue.split('.');
    const day = stringToInt(dayStr);
    const month = stringToInt(monthStr);
    const year = stringToInt(yearStr);

    if (!dayStr || !monthStr || !yearStr) {
      setError(null);
      field.onChange('');
      return;
    }

    const isValid = isValidDate(day, month, year);
    setError(isValid ? null : 'Invalid date');

    if (isValid) {
      const date = new Date(Date.UTC(year, month - 1, day));
      field.onChange(date.toISOString());
    } else {
      field.onChange('');
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={{
        required: isRequired,
      }}
      render={({ field, fieldState }: FieldValues) => {
        const errorMessageKey = getErrorKey(FieldType.input, fieldState, validationMsgKey);
        const errorMessage = error || t(errorMessageKey);

        return (
          <TextField
            {...field}
            leftIcon="calendar"
            name={name}
            placeholder={t(placeHolder)}
            error={errorMessage}
            label={t(label || name)}
            value={inputValue}
            disabled={isDisabled}
            readOnly={isDisabled}
            onChange={(value: string) => handleChange(value, field)}
          />
        );
      }}
    />
  );
};
