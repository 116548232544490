import { EventAnalytics } from './EventAnalytics';
import { Clickstream } from './clickstream';
import { LoggerProcessor } from './LoggerProcessor';
import { ClickstreamProps } from './types';

let isSetup = false;

const setupClickstream = (props: ClickstreamProps) => {
  const analyticsInstance = new EventAnalytics(props);
  const loggerInstance = new LoggerProcessor('clickstream');
  const clickstream = Clickstream.use();

  clickstream.setup(analyticsInstance, loggerInstance);
  isSetup = true;

  return {
    isSetup,
    clickstream,
  };
};

export { setupClickstream };
