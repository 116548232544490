/* eslint-disable max-len */
const MastercardIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8 19C20.7765 19 24 15.866 24 12C24 8.13401 20.7765 5 16.8 5C14.9559 5 13.2738 5.67399 12 6.78242C13.473 8.06416 14.4 9.92683 14.4 12C14.4 14.0732 13.473 15.9358 12 17.2176C13.2738 18.326 14.9559 19 16.8 19Z"
      fill="#F79E1B"
    />
    <path
      d="M11.9996 17.2179C13.4726 15.9361 14.3996 14.0735 14.3996 12.0003C14.3996 9.92713 13.4726 8.06446 11.9996 6.78271C10.5266 8.06446 9.59961 9.92713 9.59961 12.0003C9.59961 14.0735 10.5266 15.9361 11.9996 17.2179Z"
      fill="#FF5F00"
    />
    <path
      d="M7.2 5C3.22355 5 0 8.13401 0 12C0 15.866 3.22355 19 7.2 19C9.04405 19 10.7262 18.326 12 17.2176C10.527 15.9358 9.6 14.0732 9.6 12C9.6 9.92684 10.527 8.06417 12 6.78242C10.7262 5.67399 9.04405 5 7.2 5Z"
      fill="#EB001B"
    />
  </svg>
);
export default MastercardIcon;
