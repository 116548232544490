export const getGBAttrChannel = (channel: string) => {
  if (!channel) return '';

  switch (channel) {
    case 'IOS':
      return 'MOBILE_NATIVE_IOS';

    case 'ANDROID':
      return 'MOBILE_NATIVE_ANDROID';

    case 'WEB':
      return 'DESKTOP_AIR_PM';

    default:
      return 'MOBILE_WEB';
  }
};

export const arrStringToNumber = (arr?: string[]): number[] => {
  if (!Array.isArray(arr)) {
    return [];
  }

  return arr ? arr.map((str) => {
    const num = Number(str);
    return Number.isNaN(num) ? null : num;
  })
    .filter((num): num is number => num !== null) : [];
};
