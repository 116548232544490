import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ListView, ListCell, Icon } from '@modulor/react';
import { Routes } from 'Modulor/router';
import { SelectedCardType } from 'store/slices/userCard';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';

import './Cards.scss';

interface EmptyCardOptionProps {
    selectedCard: SelectedCardType;
  }

export const EmptyCardOption: FC<EmptyCardOptionProps> = ({ selectedCard }) => {
  const { t } = useTranslation();
  return (
    <div className="cards-container-wrapper">
      <Link
        className="link-box"
        to={selectedCard.isNew ? Routes.cards : '/'}
      >
        <ListView className="card-box">
          <ListCell
            text={t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.PAY_NEW_CARD`)}
            image={<Icon name="credit_card_outlined" />}
            content={(
              <div className="checkbox__in-list-cell">
                <Icon name="chevron_right" />
              </div>
              )}
          />
        </ListView>
      </Link>
    </div>
  );
};
