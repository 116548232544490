import {
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType,
} from 'store/models';
import { CardState } from 'store/slices/userCard';
import { isValidRegExp } from './validator';

export const getPattern = (
  regExp?: string,
  extendedType?: ExtendedTypes,
) => {
  const isRegExpStartsWithPlus = regExp?.startsWith('+');
  if (!regExp && !extendedType) {
    return undefined;
  }

  if (
    !regExp
    && extendedType === ExtendedTypes.CardExpMonth
  ) {
    return /(^[1-9]$)|(^0[1-9]|1[0-2]$)/g;
  }

  if (isRegExpStartsWithPlus) {
    return new RegExp(`\\${regExp}`);
  }

  return regExp && isValidRegExp(regExp) ? new RegExp(regExp) : undefined;
};

export const filterFieldsForCheckout = (fields: Parameter[], cardInfo: CardState): Parameter[] => {
  if (!fields?.length) {
    return [];
  }

  const checkoutRequiredFields = [
    ExtendedTypes.CardPan,
  ];

  if (!cardInfo.selectedCard?.is_card_token_active || cardInfo.selectedCard.isNew) {
    const checkoutMandatoryFields = [
      ExtendedTypes.CardExpYear,
      ExtendedTypes.CardCvv,
      ExtendedTypes.CardExpMonth,
      ExtendedTypes.CardCardholderName,
      ExtendedTypes.SaveRequisites,
    ];
    checkoutRequiredFields.push(...checkoutMandatoryFields);
  }

  return fields.filter((param) => (
    checkoutRequiredFields.includes(param.extendedType as ExtendedTypes)
    || (param.extendedType === ExtendedTypes.CardCvv && param.isRequired)
    || (param.extendedType === ExtendedTypes.ProfileTaxCode)
    || (param.extendedType === ExtendedTypes.DisabledInputCopy)
    || (param.type === PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType.FileUpload)
  ));
};

export const getExpirationValue = (month: string, year: string) => {
  const formattedMonth = month.length === 1 ? `0${month}` : month;
  const formattedYear = year.length === 2 ? `20${year}` : year;
  return `${formattedMonth}${formattedYear.slice(-2)}`;
};
