import fetch from 'isomorphic-fetch';

export const fetchWithTimeout = (url: string, ms: number) => {
  const controller = new AbortController();
  const promise = fetch(url, {
    signal: controller.signal,
  });

  const timeout = setTimeout(() => controller.abort(), ms);

  return promise.finally(() => clearTimeout(timeout));
};
