/* eslint-disable camelcase */
import { Middleware, MiddlewareAPI, isRejectedWithValue, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { setSessionExpired } from './slices/global';
import { RootState } from '../store';
import { NAMESPACES } from '../services/constants';

interface DefaultActionPayload {
  data?: {
    error_code?: number;
  };
}

type Action = PayloadAction<DefaultActionPayload> | PayloadAction<string>;

export const rtkQueryErrorLogger: Middleware<
  { api: MiddlewareAPI<Dispatch<Action>, RootState> },
  RootState
> = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const sessionErrorMessage = `${NAMESPACES.INFO}:CASHIER.PH2.ERROR_MESSAGE.17`;
    const { error_code } = (action.payload as DefaultActionPayload)?.data || {};
    const sessionExpiredError = error_code === 17 || sessionErrorMessage === action.payload;

    if (sessionExpiredError) {
      api.dispatch(setSessionExpired());
    }
  }

  return next(action);
};
