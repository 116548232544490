import { createElement } from 'react';
import { Icon } from '@modulor/react';
import creditCardType from 'credit-card-type';
import { CARDS_ICON_TYPE_MAP } from 'Modulor/components/Cards/utils';

export const getCardsIcon = (digits: string | null | undefined) => {
  if (digits && !!creditCardType(digits).length) {
    const detectedType = creditCardType(digits)[0].type;
    const icon = CARDS_ICON_TYPE_MAP[detectedType];
    return detectedType && icon ? createElement(icon) : <Icon name="credit_card_outlined" />;
  }
  return <Icon name="credit_card_outlined" />;
};

export const getCardsTypeName = (digits: string | null | undefined) => (
  digits && creditCardType(digits)?.length ? creditCardType(digits)[0].niceType : 'Card');
