/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { FlatStringObject, RequestFields } from "../../commonTypes";

export enum PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType {
  CreditCard = "credit_card",
  YandexMoney = "yandex_money",
  Qiwi = "qiwi",
  Skrill = "skrill",
  Neteller = "neteller",
  WebMoney = "web_money",
  BetShop = "bet_shop",
  Popup = "popup",
  SaveRequisites = "save_requisites",
  BanksZotapay = "banks_zotapay",
  BanksRealdeposits = "banks_realdeposits",
  BanksDirecta24Brazil = "banks_directa24_brazil",
  BanksDirecta24Nigeria = "banks_directa24_nigeria",
  BanksDirecta24Mexico = "banks_directa24_mexico",
  Predefined = "predefined",
  PhoneWithCountry = "phone_with_country",
  CardPan = "card_pan",
  CardCvv = "card_cvv",
  CardExpYear = "card_exp_year",
  CardExpMonth = "card_exp_month",
  CardCardholderName = "card_cardholder_name",
  PhoneWithoutCountry = "phone_without_country",
  PhoneWithCountryPlus = "phone_with_country_plus",
  Phone = 'Phone',
  Custom = 'custom',
  CardId = 'card_id',
  CardBin = 'card_bin',
  ProfileTaxCode = 'profile_tax_code',
  DisabledInputCopy = 'disabled_input_copy',
}
export enum PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType {
  FileUpload = 'file_upload',
}

export enum PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType {
  Input = "input",
  Check = "check",
  Select = "select",
  Date = "date",
  Info = "info",
  UserIp = "user_ip",
  UserEmail = "user_email",
  UserPhone = "user_phone",
  UserCountry = "user_country",
  UserFirstName = "user_first_name",
  UserLastName = "user_last_name",
  UserMiddleName = "user_middle_name",
  UserTaxCode = "user_tax_code",
  UserVerified = "user_verified",
}

export enum PayHubAdminPanelDomainEnumsParameterDataType {
  String = "String",
  Float = "Float",
  Integer = "Integer",
  Boolean = "Boolean",
}

export enum PayHubAdminPanelDomainEnumsRedirectType {
  Redirect = "redirect",
  Blank = "blank",
  Top = "top",
  Parent = "parent",
}

export enum PayHubAdminPanelDomainMethodsEnumsPayMethodType {
  CreditAndDebitCards = "Credit and Debit Cards",
  EWallets = "E-Wallets",
  BankTransfers = "Bank Transfers",
  PrepaidCash = "Prepaid & Cash",
  Mobile = "Mobile",
  BetShops = "Bet Shops",
  Terminal = "Terminal",
  Crypto = "Crypto",
  OtherTypes = "Other types",
}

export enum PayHubAdminPanelDomainPayGroupsEnumsNativePayType {
  GooglePay = "GooglePay",
  ApplePay = "ApplePay",
  SamsungPay = "SamsungPay",
}

export enum PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType {
  Activity = "activity",
  Info = "info",
  NestedList = "nested_list",
}

export enum PayHubAdminPanelDomainPayGroupsEnumsWithholdingType {
  Payment = "Payment",
  Payout = "Payout",
}

export interface PayHubCashierContractsCashier3DSResultRequest {
  pares?: string | null;
  cres?: string | null;
  md?: string | null;
}

export interface PayHubCashierContractsCashier3DSResultResponse {
  session_id?: string;
  status?: string;
  redirect_type?: PayHubAdminPanelDomainEnumsRedirectType;
  action?: PayHubCashierContractsEnumsCashierActionType;
  request_method?: string;
  request_url?: string;
  request_fields?: FlatStringObject
  result_status?: PayHubCashierContractsEnumsSubmitPaymentStatus;
  result_description?: string | null;
}

export interface PayHubCashierContractsCashierLLAResultResponse {
  action: PayHubCashierContractsEnumsCashierActionType;
  redirect_data: {
    url: string;
    method: string;
    fields: RequestFields
  }
}

export interface PayHubCashierContractsCashier3DSResultResponse {
  session_id?: string;
  status?: string;
  redirect_type?: PayHubAdminPanelDomainEnumsRedirectType;
  action?: PayHubCashierContractsEnumsCashierActionType;
  request_method?: string;
  request_url?: string;
  request_fields?: FlatStringObject
  result_status?: PayHubCashierContractsEnumsSubmitPaymentStatus;
  result_description?: string | null;
}

export interface PayHubCashierContractsCashierBrowserDataRequest {
  color_depth: string;
  language: string;
  time_zone: string;
  timezone_name: string;
  java_enabled: string;
  java_script_enabled?: string | null;
  screen_height: string;
  screen_width: string;
  window_height: string;
  window_width: string;
  user_agent: string;
}

export interface PayHubCashierContractsCashierCardTokenizationRequest {
  /** @pattern ^[0-9]{8,19}$ */
  card_number: string;
  /** @pattern ^(0[1-9]|1[0-2])$ */
  expiry_month?: string | null;
  /** @pattern ^[0-9]{2}$ */
  expiry_year?: string | null;
  /** @pattern ^[a-zA-Z0-9\-_. ]{1,26}$ */
  cardholder_name?: string | null;
}

export interface PayHubCashierContractsCashierCardTokenizationResponse {
  /** @format date-time */
  datetime?: string;
  status?: PayHubDomainInfrastructureSuccessFailStatus;
}

export interface PayHubCashierContractsCashierDeclinePageSettingsResponse {
  user_decline_message_translation_key?: string;
  retry_button_translation_key?: string;
  apg_suggestion_translation_key?: string;
  apg_suggest_most_relevant_alt_options?: boolean;
  apg_suggest_change_method?: boolean;
}

export interface PayHubCashierContractsCashierMetric {
  name?: string | null;
  /** @format int64 */
  value?: number;
}

export interface PayHubCashierContractsCashierMetricsRequest {
  metrics: PayHubCashierContractsCashierMetric[];
  tags: FlatStringObject
}

export enum CurrencyDisplaySetting {
  digitsIso = 1,
  isoDigits = 2,
  digitsSymbol = 3,
  symbolDigits = 4,
}
export interface PayHubCashierContractsCashierSettingsResponse {
  country_filtering_enabled?: boolean;
  /** @format int32 */
  user_preferred_country_id?: number | null;
  /** @format int32 */
  user_current_country_id?: number | null;
  platform?: PayHubDomainSessionsEnumsPlatformType;
  country_code?: string | null;
  allow_preset_amount?: boolean | null;
  /** @format double */
  preset_amount?: number | null;
  preset_currency?: string | null;
  preset_behavior?: string | null;
  sophia_integration?: boolean | null;
  user_phone?: string | null;
  logo_image_height?: number;
  logo_image_width?: number;
  success_page_redirect_timeout?: number;
  user_ams_segments?: string[];
  currency_display_setting_id?: CurrencyDisplaySetting;
}

export interface PayHubCashierContractsCountryResponse {
  /** @format int32 */
  id?: number;
  name?: string | null;
  alpha2?: string | null;
}

export interface PayHubCashierContractsCreateRequisiteRequest {
  /** @format uuid */
  requisite_type_id?: string;
  requisite?: string;
  /** @pattern ^[0-9]{8,19}$ */
  pan?: number;
}

export interface PayHubCashierContractsCreateRequisiteResponse {
  /** @format int64 */
  requisite_id?: number;
}

export interface PayHubCashierContractsCurrencyResponse {
  /** @format int32 */
  currency_id?: number;
  name?: string | null;
  alpha3?: string | null;
  iso_number?: string | null;
  /** @format int32 */
  precision?: number;
}

export interface PayHubCashierContractsDeleteUserCardRequest {
  /** @format int64 */
  card_id: number;
  withholding_type: PayHubAdminPanelDomainPayGroupsEnumsWithholdingType;
}

export enum PayHubCashierContractsEnumsCashierActionType {
  Redirect = "redirect",
  Result = "result",
  Await = "await",
}

export enum PayHubCashierContractsEnumsDeclinePagesV2UserGroup {
  V0A0 = "v0_A0",
  V0A1 = "v0_A1",
  V1B = "v1_B",
  V2C = "v2_C",
}

export enum PayHubCashierContractsEnumsSubmitPaymentStatus {
  Success = "success",
  Fail = "fail",
  Processing = "processing",
  Redirect = "redirect",
}

export enum PayHubCashierContractsEnumsUserContactType {
  Phone = "phone",
  Email = "email",
}

export interface PayHubCashierContractsGenerateOTPResponse {
  status?: PayHubDomainInfrastructureGenerateOtpStatus;
  error?: string | null;
  can_resend?: boolean | null;
}

export interface PayHubCashierContractsHideUserCardRequest {
  /** @format int64 */
  card_id: number;
}

export interface PayHubCashierContractsInitParamsResponse {
  name?: string | null;
  type?: PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType;
  extended_type?: PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType;
  data_type?: PayHubAdminPanelDomainEnumsParameterDataType;
  regexp?: string | null;
  label?: string | null;
  place_holder?: string;
  place_holder_empty_list?: string | null;
  place_holder_empty_list_error?: string | null;
  is_required?: boolean;
  is_read_only?: boolean;
  is_otp_requisites?: boolean;
  options?: PayHubCashierContractsParamOptionResponse[] | null;
  user_requisite?: string | null;
  /** @format uuid */
  user_requisite_type_id?: string | null;
  verify_new_payout_account?: boolean;
  validation_msg_key?: string;
  contract_details?: [];
}

export interface PayHubCashierContractsInitPaymentLimitsResponse {
  /** @format double */
  min_amount?: number | null;
  /** @format double */
  max_amount?: number | null;
  /** @format double */
  max_recurring_amount?: number | null;
}

export interface PayHubCashierContractsLastDeclineInfoResponse {
  /** @format date-time */
  withdrawal_date?: string | null;
  decline_sub_reason?: string | null;
  comment?: string | null;
}

export interface PayHubCashierContractsLostProfitMerchantBonus {
  pay_group: string;
  display_on_pay_group?: string[] | null;
  /** @format double */
  available_limit?: number | null;
  tiers: PayHubCashierContractsLostProfitMerchantBonusTier[];
}

export interface BonusInfoResponse {
  bonusName: string;
  bonusDescription: string;
  minDepositAmount: number;
  maxDepositAmount: number;
  absoluteBonusValue?: number | null;
  percentBonusValue?: number | null;
  pay_groups?: string[];
  maxBonusValue?: number | null;
}

export interface PayHubCashierContractsLostProfitMerchantBonusTier {
  /** @format double */
  from?: number;
  /** @format double */
  to?: number;
  /** @format double */
  percent_value?: number | null;
  /** @format double */
  absolute_value?: number | null;
}

export interface PayHubCashierContractsParamOptionResponse {
  label?: string;
  value?: any;
  additional_data?: Record<string, any>;
  is_selected?: boolean | null;
}

export interface PayHubCashierContractsPaymentGroupInfo {
  /** @format int32 */
  id?: number;
  action_type?: PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType;
  logo?: string | null;
  info?: string | null;
  info_in_iframe?: boolean;
  public_name?: string | null;
  short_name?: string | null;
  /** @format int32 */
  arrangement?: number;
  popular?: boolean;
  native_pay_type?: PayHubAdminPanelDomainPayGroupsEnumsNativePayType;
  last_used?: boolean;
  pay_method_type?: PayHubAdminPanelDomainMethodsEnumsPayMethodType | string;
  enabled?: boolean;
  auto_submit?: boolean;
  currencies?: PayHubCashierContractsPaymentsInitPaymentGroupsCurrencyResponse[] | null;
  worldwide?: boolean;
  allow_pre_selected_method?: boolean;
  countries?: PayHubCashierContractsCountryResponse[] | null;
  restricted_until?: string;
  merchant_user_fee?: MerchantUserFeeResponce;
  show_in_carousel?: boolean;
}

export interface PayHubCashierContractsPaymentsAltProcessingLlaRedirectData {
  url?: string | null;
  method?: string | null;
  fields?: FlatStringObject
}

export interface PayHubCashierContractsPaymentsAltProcessingLlaResponse {
  action?: PayHubCashierContractsEnumsCashierActionType;
  redirect_data?: PayHubCashierContractsPaymentsAltProcessingLlaRedirectData;
}

export interface PayHubCashierContractsPaymentsInitPaymentGroupsCurrencyResponse {
  currency?: PayHubCashierContractsCurrencyResponse;
  limits?: PayHubCashierContractsInitPaymentLimitsResponse;
  fee?: string | null;
  processing_time_translation_key?: string;
  show_processing_time?: boolean | null;
}

export interface PayHubCashierContractsPaymentsInitPaymentGroupsResponse {
  payment_groups?: PayHubCashierContractsPaymentGroupInfo[] | null;
  payout_groups?: PayHubCashierContractsPaymentGroupInfo[] | null;
  view_data?: PayHubCashierContractsViewDataResponse[] | null;
  cashier_settings: PayHubCashierContractsCashierSettingsResponse;
  withdrawal_reverse?: PayHubCashierContractsWithdrawalReverseResponse;
  lost_profit_merchant_bonus?: PayHubCashierContractsLostProfitMerchantBonus[] | null;
  merchant_url?: string;
  merchant_success_url?: string;
  merchant_fail_url?: string;
  merchant_processing_url?: string;
  merchant_redirect_type?: PayHubAdminPanelDomainEnumsRedirectType;
  auth_token?: string | null;
  tax?: PayHubCashierContractsTaxResponse;
  /** @format double */
  user_balance?: number;
  cashier_features?: string[];
  user_bonuses?: BonusInfoResponse[];
}

export interface PayHubCashierContractsPaymentsOtpPaymentGroupRequest {
  /**
   * @format double
   * @pattern ^\d{1,15}(\.\d{1,4})?$
   */
  amount: number;
  currency: string;
  parameters?: Record<string, any>;
}

export interface PayHubCashierContractsPaymentsReversePendingWithdrawalResponse {
  /** @format double */
  success_amount?: number;
  /** @format double */
  fail_amount?: number;
}

export interface PayHubCashierContractsPaymentsSelectPaymentGroupRequest {
  currency: string;
  /** @format int32 */
  preferred_country_id?: number | null;
}

export interface PayHubCashierContractsPaymentsSelectPaymentGroupResponse {
  currency?: PayHubCashierContractsCurrencyResponse;
  limits?: PayHubCashierContractsInitPaymentLimitsResponse;
  amount_blocks?: number[];
  individual_amount_blocks?: number[] | null;
  parameters?: PayHubCashierContractsInitParamsResponse[] | null;
  fee?: string;
  processing_time_translation_key?: string;
  view_data?: PayHubCashierContractsViewDataResponse[] | null;
  footer?: PayHubCashierContractsViewDataResponse;
  amount_reg_exp_fe?: string | null;
  processing_currency?: PayHubCashierContractsCurrencyResponse;
  /** @format double */
  processing_currency_rate?: number | null;
  show_processing_currency?: boolean;
  allow_preset_amount?: boolean | null;
  /** @format double */
  preset_amount?: number | null;
  preset_currency?: string | null;
  preset_behavior?: string | null;
  input_type?: string | null;
  dropdown_list?: number[] | null;
  auto_submit?: boolean;
  info?: string;
}

export interface PayHubCashierContractsPaymentsSubmitPaymentGroupRequest {
  /**
   * @format double
   * @pattern ^\d{1,15}(\.\d{1,4})?$
   */
  amount: number;
  currency: string;
  parameters?: Record<string, any>;
  browser_data?: PayHubCashierContractsCashierBrowserDataRequest;
  fingerprint?: string | null;
}

export interface PayHubCashierContractsPaymentSessionStateResponse {
  session_id: string;
  action?: PayHubCashierContractsEnumsCashierActionType;
  result_status?: PayHubCashierContractsEnumsSubmitPaymentStatus;
  session_status?: PayHubDomainSessionsEnumsIppsSessionStatus;
  transaction_status?: PayHubDomainOrdersEnumsPayNotificationStatus;
  experiment_user_group?: PayHubCashierContractsEnumsDeclinePagesV2UserGroup;
  decline_page_settings?: PayHubCashierContractsCashierDeclinePageSettingsResponse;
  error_fatal?: boolean;
  success_url?: string,
  redirect_timeout?: number,
  fail_url?: string,
  processing_url?: string,
  merchant_url?: string,
  processing_page_redirect_timeout?: string,
  fail_page_redirect_timeout?: string,
  success_page_redirect_timeout?: string
}

export interface PayHubCashierContractsPayoutsInitPayoutGroupsCurrencyResponse {
  currency?: PayHubCashierContractsCurrencyResponse;
  limits?: PayHubCashierContractsInitPaymentLimitsResponse;
  fee?: string | null;
  processing_time_translation_key?: string;
  show_processing_time?: boolean | null;
}

export interface PayHubCashierContractsPayoutsInitPayoutGroupsResponse {
  payout_groups?: PayHubCashierContractsPayoutsPayoutGroupInfo[] | null;
  view_data?: PayHubCashierContractsViewDataResponse[] | null;
  cashier_settings: PayHubCashierContractsCashierSettingsResponse;
  last_decline_info?: PayHubCashierContractsLastDeclineInfoResponse;
  tax?: PayHubCashierContractsTaxResponse;
  merchant_url?: string;
  merchant_success_url?: string;
  merchant_fail_url?: string;
  merchant_processing_url?: string;
  auth_token?: string | null;
  /** @format double */
  user_balance?: number;
  cashier_features?: string[];
  lost_profit_merchant_bonus?: PayHubCashierContractsLostProfitMerchantBonus[] | null;
  user_bonuses?: BonusInfoResponse[];
}

export interface PayHubCashierContractsPayoutsPayoutGroupInfo {
  /** @format int32 */
  id?: number;
  logo?: string | null;
  info?: string | null;
  info_in_iframe?: boolean;
  public_name?: string | null;
  short_name?: string | null;
  action_type?: PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType;
  /** @format int32 */
  arrangement?: number;
  popular?: boolean;
  native_pay_type?: PayHubAdminPanelDomainPayGroupsEnumsNativePayType;
  last_used?: boolean;
  pay_method_type?: PayHubAdminPanelDomainMethodsEnumsPayMethodType;
  enabled?: boolean;
  currencies?: PayHubCashierContractsPayoutsInitPayoutGroupsCurrencyResponse[] | null;
  worldwide?: boolean;
  countries?: PayHubCashierContractsCountryResponse[] | null;
}

export interface PayHubCashierContractsPayoutsSelectPayoutGroupRequest {
  currency: string;
  /** @format int32 */
  preferred_country_id?: number | null;
}

export interface MerchantUserFeeResponce {
  percentage?: number,
  fix?: number,
}

export interface PayHubWidgetContractsPayoutsSelectPayoutGroupResponse {
  currency?: PayHubCashierContractsCurrencyResponse;
  limits?: PayHubCashierContractsInitPaymentLimitsResponse;
  parameters?: PayHubCashierContractsInitParamsResponse[] | null;
  fee?: string | null;
  processing_time_translation_key?: string;
  view_data?: PayHubCashierContractsViewDataResponse[] | null;
  footer?: PayHubCashierContractsViewDataResponse;
  amount_reg_exp_fe?: string | null;
  processing_currency?: PayHubCashierContractsCurrencyResponse;
  /** @format double */
  processing_currency_rate?: number | null;
  show_processing_currency?: boolean;
  input_type?: string | null;
  dropdown_list?: number[] | null;
  user_fee?: PayHubCashierContractsPayoutsUserFeeResponse;
  allow_new_payout_account?: boolean;
  payout_account_verification_blocked?: boolean;
  /** @format date-time */
  payout_account_verification_blocked_until?: string | null;
  support_url?: string;
  trusted_contacts?: PayHubCashierContractsTrustedContactResponse[];
  amount_blocks?: number[];
  auto_submit?: boolean;
  preset_behavior?: string | null,
  preset_amount?: number | null,
  preset_currency?: string | null,
  allow_preset_amount?: boolean | null,
  merchant_user_fee?: MerchantUserFeeResponce,
  workflow_type?: string;
  info?: string;
}

export interface PayHubCashierContractsPayoutsSubmitPayoutGroupRequest {
  /**
   * @format double
   * @pattern ^\d{1,15}(\.\d{1,4})?$
   */
  amount: number;
  currency: string;
  parameters?: Record<string, any>;
  is_new_payout_account?: boolean;
}

export interface PayHubCashierContractsPayoutsUserFeeResponse {
  /** @format double */
  percentage?: number;
}

export interface PayHubCashierContractsSendOTPRequest {
  contact_type: PayHubCashierContractsEnumsUserContactType;
}

export interface PayHubCashierContractsSendOTPResponse {
  success?: boolean;
  /** @format int32 */
  max_request_count?: number | null;
  /** @format int32 */
  error_code?: number | null;
  /** @format date-span */
  block_duration?: string | null;
}

export interface PayHubWidgetContractsSubmitPaymentResponse {
  action?: PayHubCashierContractsEnumsCashierActionType;
  request_method?: string;
  request_url?: string;
  url?: string;
  status?: string;
  request_fields?: FlatStringObject
  result_status?: PayHubCashierContractsEnumsSubmitPaymentStatus;
  result_description?: string;
  redirect_type?: PayHubAdminPanelDomainEnumsRedirectType;
  redirect_data?: FlatStringObject;
}

export interface PayHubWidgetContractsSubmitPayoutResponse {
  action?: PayHubCashierContractsEnumsCashierActionType;
  request_method?: string;
  request_url?: string;
  status?: string;
  request_fields?: FlatStringObject
  result_status?: PayHubDomainInfrastructureSuccessFailStatus;
  result_description?: string;
  redirect_type?: PayHubAdminPanelDomainEnumsRedirectType;
}

export interface PayHubCashierContractsTaxResponse {
  currency?: string;
  name?: string;
  description?: string;
  tiers?: PayHubCashierContractsTierResponse[];
  deductible_stake_amount?: number;
}

export interface PayHubCashierContractsTierResponse {
  from?: number;
  to?: number;
  percent_value?: number;
  absolute_value?: number;
}

export interface PayHubCashierContractsTrustedContactResponse {
  type?: PayHubCashierContractsEnumsUserContactType;
  value?: string;
}

export interface PayHubCashierContractsLLAStatusResponse {
  status: string;
  url?: string;
  request_url?: string;
  result_status?: string;
}

export interface PayHubWidgetContractsUserCardResponse {
  /** @format int64 */
  id?: number;
  bin?: string | null;
  last4?: string | null;
  /** @format int32 */
  exp_year?: number | null;
  /** @format int32 */
  exp_month?: number | null;
  cardholder_name?: string | null;
  is_last_used?: boolean;
  is_card_token_active?: boolean;
}

export interface PayHubCashierContractsUserCardsResponse {
  cards?: PayHubWidgetContractsUserCardResponse[] | null;
}

export interface PayHubCashierContractsVerifyOTPRequest {
  otp: string;
}

export interface PayHubCashierContractsVerifyOTPResponse {
  success?: boolean;
  /** @format int32 */
  error_code?: number | null;
  attempts_left?: number | null;
}

export interface PayHubCashierContractsViewDataOptionsResponse {
  label?: string | null;
}

export interface PayHubCashierContractsViewDataResponse {
  type?: PayHubDomainCashierEnumsCashierViewDataType;
  style?: PayHubDomainCashierEnumsCashierViewDataStyle;
  key?: string;
  value?: string;
  options?: PayHubCashierContractsViewDataOptionsResponse[];
  params?: Record<string, any>;
}

export interface PayHubCashierContractsWithdrawalReverseResponse {
  /** @format double */
  amount?: number;
  currency?: string;
}

export interface PayHubCommonErrorsErrorResult {
  /** @format int32 */
  error_code?: number;
  error_message?: string | null;
}

export enum PayHubDomainCashierEnumsCashierViewDataStyle {
  Plain = "plain",
  Bold = "bold",
  Blind = "blind",
  Error = "error",
  Warning = "warning",
  Info = "info",
  PlainBigger = "plain_bigger",
}

export enum PayHubDomainCashierEnumsCashierViewDataType {
  Text = "text",
  Line = "line",
  InformationBlock = "information_block",
  Popup = "popup",
  Collapse = "collapse",
  AdditionalInfo = "additional_info",
  Wager = 'wager',
}

export enum PayHubDomainInfrastructureGenerateOtpStatus {
  Success = "success",
  Fail = "fail",
}

export enum PayHubDomainInfrastructureSuccessFailStatus {
  Success = "success",
  Fail = "fail",
}

export enum PayHubDomainOrdersEnumsPayNotificationStatus {
  Processing = "processing",
  Success = "success",
  Fail = "fail",
  ProcessingError = "processing_error",
  OrderCancelled = "order_cancelled",
  Awaiting = "awaiting",
}

export enum PayHubDomainSessionsEnumsIppsSessionStatus {
  New = "new",
  Init = "init",
  Submit = "submit",
  Result = "result",
  Select = "select",
}

export enum PayHubDomainSessionsEnumsPlatformType {
  Desktop = "desktop",
  Mobile = "mobile",
  NativeIos = "native_ios",
  NativeAndroid = "native_android",
}

export interface PayHubWidgetContractsRecurringCheckResponse {
  is_cvv_required: boolean,
}
