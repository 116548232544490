import { Notifications } from 'store/models';
import { useAppDispatch } from 'store/hooks';
import { setNotifications } from 'store/slices/notifications';
import { useEffect } from 'react';

export const useSetNotificationsHook = (notifications: Notifications = []): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setNotifications(notifications));
  }, [notifications.length]);
};
