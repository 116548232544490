import { Dispatch, SetStateAction } from 'react';
import { Divider, Icon, ListCell, ListView, Radio, Typography } from '@modulor/react';
import { PayHubCashierContractsParamOptionResponse } from 'store/models/APIModels';
import clx from 'classnames';
import { PreparedCells } from './types';

interface BetShopsListCellProps {
    rootCell: PreparedCells;
    currentBetShop?: PayHubCashierContractsParamOptionResponse;
    expandedCells: string[];
    setExpandedCells: Dispatch<SetStateAction<string[]>>;
    onSelectOption: (option: PayHubCashierContractsParamOptionResponse) => void;
}

export const BetShopsListCell = ({
  rootCell,
  currentBetShop,
  expandedCells,
  setExpandedCells,
  onSelectOption,
}: BetShopsListCellProps) => {
  const { key } = rootCell;
  const toggleContent = (selectedOption: string) => {
    setExpandedCells((prev) => (prev.includes(selectedOption) ? prev.filter((k) => k !== selectedOption) : [
      ...prev,
      selectedOption,
    ]));
  };

  return (
    <ListCell
      key={key}
      text={rootCell.label}
      onClick={() => toggleContent(key)}
      dividerHidden
      icon={(
        <Icon
          fill="icon-deposit"
          name="pin_outlined"
        />
                  )}
      rightImage={(
        <Icon
          size={24}
          name={expandedCells.includes(key) ? 'chevron_up' : 'chevron_down'}
        />
                )}
      bottomContainerContent={(
        <>
          {(expandedCells.includes(key) && (
          <ListView>
            {rootCell.value?.options.map((option: PayHubCashierContractsParamOptionResponse) => (
              <ListCell
                key={option.label}
                className="expanded-content"
                subtitle={(
                  <>
                    <div>
                      <Typography variant="caption-1-regular">
                        {option.label}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="caption-1-regular">
                        {option.additional_data?.working_hours}
                      </Typography>
                    </div>
                  </>
                          ) as unknown as string}
                content={(
                  <Radio
                    className="action__in-list-cell radio-btn"
                    value={option.value || ''}
                    onChange={() => onSelectOption({
                      label: rootCell.label,
                      value: option.value,
                      additional_data: {
                        label: option.label,
                        workingHours: option.additional_data?.working_hours,
                      },
                    })}
                    checked={option.value === currentBetShop?.value}
                  />
                )}
              />
            ))}
          </ListView>

          ))}
          <Divider className={clx('divider', {
            'content-divider': expandedCells.includes(rootCell.key),
          })}
          />
        </>
        ) as unknown as string}
    />
  );
};
