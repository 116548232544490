/* eslint-disable camelcase */
import { useGetSessionId } from 'Modulor/hooks';

export const useGetClickStreamCashierContext = (method: string) => {
  const sessionId = useGetSessionId();

  return {
    schema: 'iglu:com.psp/context_cashier/jsonschema/2-0-0',
    data: {
      session_id: sessionId,
      session_type: method,
    },
  };
};
