/* eslint-disable camelcase */
import qs from 'query-string';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import { setReqHeaders } from 'utils/common';
import { WithdrawalRejectRequestResponse } from '../models';
import { transformWithdrawalRejectRequestResponse } from '../formatters/withdrawalReject';

const CASHIER_SERVICE_ENV = process.env.NODE_ENV === 'production' ? '/api/cashier-service/' : '/cash-ph20-service/';
const CASHIER_API = `${CASHIER_SERVICE_ENV}v1.0`;

export const withdrawalRejectAPI = createApi({
  reducerPath: 'withdrawalRejectAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: CASHIER_API,
    prepareHeaders(headers, { getState }) {
      const { session_id = '' } = qs.parse(window?.location?.search || '');
      const { sessionId } = (getState() as RootState).common;
      setReqHeaders(headers, sessionId || `${session_id}`);
    },
  }),
  endpoints(builder) {
    return {
      withdrawalRejectRequest: builder.query< WithdrawalRejectRequestResponse, void >({
        query: () => 'payments/reverse-pending-withdrawal',
        transformResponse: (
          response: WithdrawalRejectRequestResponse,
        ) => transformWithdrawalRejectRequestResponse(response) as unknown as WithdrawalRejectRequestResponse,
      }),
    };
  },
});

export const {
  useLazyWithdrawalRejectRequestQuery,
} = withdrawalRejectAPI;
export default withdrawalRejectAPI.reducer;
