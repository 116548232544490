/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Icon } from '@modulor/react';
import { Routes } from 'Modulor/router';
import { useLazySendCodeQuery } from 'store/services/accountManagementAPI';
import { disableAccountVerification } from 'store/slices/withdrawal';
import { setSendCodeInfo } from 'store/slices/accountManagement';
import { RequisiteTypes, FlowTypes } from 'store/constants';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamPayGroupCashierContext,
  getClickStreamEvent,
} from 'services/clickstream';
import { useNavigateWithSearch } from 'Modulor/hooks';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';
import { getBlockedDurationValue } from '../../OTP/utils';

import './VerifyPage.scss';

const ONE_MIN_TIMER = 60;

interface CodeReceiverProps {
  contactType: string;
  switchContactType: VoidFunction;
  hasOnlyOneTrustedContact: boolean;
}

export const CodeReceiver: React.FC<CodeReceiverProps> = (props) => {
  const { contactType, switchContactType, hasOnlyOneTrustedContact } = props;
  const [showTimer, setTimerOn] = useState<boolean>(false);
  const [codeTitle, setGetCodeTitle] = useState<string>('PH.BUTTON.RECEIVE_CODE');
  const [seconds, setSeconds] = useState<number>(ONE_MIN_TIMER);
  const [requestCodeAttempt, setRequestCodeAttempt] = useState<number>(1);
  const [sendCodeTrigger, verifCodeResult] = useLazySendCodeQuery();
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();
  const dispatch = useDispatch();
  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(FlowTypes.withdrawal);
  const payGroupContext = getClickStreamPayGroupCashierContext();

  const handleSendCode = () => {
    const event = getClickStreamEvent.cashier_payout_otp_request_button_click(requestCodeAttempt);
    clickstream.push(event, [cashierContext,
      payGroupContext]);
    setRequestCodeAttempt((prev: number) => prev + 1);
    setTimerOn(true);
    sendCodeTrigger({
      contactType,
    });
    setGetCodeTitle(`${NAMESPACES.PW_KEYS}:PH.BUTTON.RECEIVE_CODE`);
  };

  useEffect(() => {
    if (seconds > 0 && showTimer) {
      const intervalId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
    if (seconds === 0) {
      setSeconds(ONE_MIN_TIMER);
      setTimerOn(false);
    }
  }, [seconds,
    showTimer]);

  useEffect(() => {
    if (verifCodeResult?.data?.maxRequestCount) {
      dispatch(setSendCodeInfo(verifCodeResult?.data));
    }
    if (!verifCodeResult.data?.success && verifCodeResult.data?.blockDuration) {
      dispatch(disableAccountVerification({
        accountVerifBlocked: true,
        accountVerifBlockedUntil: getBlockedDurationValue(verifCodeResult.data.blockDuration),
      }));
      navigate(Routes.accountManagementBlockedPage);
    }
  }, [verifCodeResult]);

  const preparedSeconds = seconds.toString().padStart(2, '0');
  const countdown = seconds === ONE_MIN_TIMER ? '1:00' : `0:${preparedSeconds}`;
  const changeMethodKindTranslation = contactType === RequisiteTypes.phone ? 'SMS' : 'EMAIL';
  const changeMethodTranslation = t(`${
    NAMESPACES.PW_KEYS
  }:PH.BUTTON.CHANGE_VERIFICATION_METHOD.${changeMethodKindTranslation}`);
  const changeMethodTranslationStandartText = changeMethodTranslation.split('<0>')[0];
  const changeMethodTranslationClickableText = changeMethodTranslation.split('<0>')[1];

  return (
    <>
      {showTimer ? (
        <div className="refresh-code-block">
          <Icon name="clock_outlined" />
          <Typography
            className="get-new-code-text"
            variant="body-regular"
          >
            {t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.RECEIVE_TIME_LIMIT`, {
              time: countdown,
            })}
          </Typography>
        </div>
      ) : (
        <div
          className="refresh-code-block send-code-button"
          onClick={handleSendCode}
        >
          <Icon
            className="icon"
            name="refresh"
            style={{
              color: 'var(--text-link)',
            }}
          />
          <Typography
            variant="body-regular"
            style={{
              color: 'var(--text-link)',
            }}
          >
            {t(codeTitle)}
          </Typography>
        </div>
      )}
      {!hasOnlyOneTrustedContact && (
        <div className="switch-method-block">
          <Typography
            variant="body-regular"
          >
            {changeMethodTranslationStandartText}
          </Typography>
          <Typography
            variant="body-regular"
            className="toggler"
            onClick={switchContactType}
            style={{
              color: 'var(--text-link)',
            }}
          >
            {changeMethodTranslationClickableText}
          </Typography>
        </div>
      )}
    </>
  );
};
