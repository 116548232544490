import { Routes } from 'Modulor/router';
import { AbstractSubmitStrategy } from './AbstractSubmitStrategy';
import { SubmitStrategyHandleProps } from './models';

export class OTPStrategy implements AbstractSubmitStrategy {
  public handle(props: SubmitStrategyHandleProps):void {
    const {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      navigate = () => {},
    } = props;

    navigate(Routes.otp);
  }
}
