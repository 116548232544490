/* eslint-disable react/no-danger */
import { FC } from 'react';
import { FlowTypes } from 'store/constants';
import { Icon, Typography } from '@modulor/react';
import { selectInfoMethod } from 'utils';
import { useAppDispatch } from 'store/hooks';
import { PayHubDomainSessionsEnumsPlatformType } from 'store/models';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamEvent,
  getClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { NAMESPACES } from 'services/constants';

import './InfoLink.scss';
import { useTranslation } from 'react-i18next';

interface InfoLinkProps {
  descriptionUrl?: string;
  flowType: FlowTypes;
  methodName: string;
  isSelfTarget: boolean;
  platform: PayHubDomainSessionsEnumsPlatformType;
}

export const InfoLink: FC<InfoLinkProps> = (props) => {
  const {
    methodName,
    descriptionUrl,
    flowType,
    isSelfTarget,
    platform,
  } = props;
  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(flowType);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  if (!descriptionUrl) {
    return null;
  }

  const clickHandler = () => {
    const event = getClickStreamEvent.cashier_method_hint_click;
    clickstream.push(event, [cashierContext,
      payGroupContext]);
    selectInfoMethod({
      descriptionUrl,
      isSelfTarget,
      dispatch,
      platform,
    });
  };

  const keyMap = {
    [FlowTypes.deposit]: `${NAMESPACES.PW_KEYS}:PH.DEPOSIT.INSTRUCTION`,
    [FlowTypes.withdrawal]: `${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.INSTRUCTION`,
  };

  const translatedName = t(methodName);
  const text = t(keyMap[flowType], {
    method: translatedName,
  });

  return (
    <div className="info-link-container">
      <Typography
        variant="caption-1-regular"
        tag="p"
        className="info-link"
        onClick={clickHandler}
      >
        <Icon
          size={12}
          name="info_circle_outlined"
          fill="text-link"
        />
        &nbsp;
        <span
          className="info-link__text"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </Typography>
    </div>
  );
};
