export enum NAMESPACES {
  'PAYGOUPS_AND_PROCESSING_TIME' = 'paygoups_and_processing_time',
  'INFO' = 'info',
  'PW_KEYS' = 'pw_keys',
  'ERRORS' = 'errors',
  'DECLINE' = 'decline',
}

export const NAMESPACE_LIST = ['paygoups_and_processing_time',
  'info',
  'pw_keys',
  'errors',
  'decline'];
