/* eslint-disable max-len */
import React from 'react';

const ProcessingStatusIcon = () => (
  <svg
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 40C9 40 0 31 0 20S9 0 20 0s20 9 20 20-9 20-20 20Zm0-36C11.2 4 4 11.2 4 20s7.2 16 16 16 16-7.2 16-16S28.8 4 20 4Zm8.6 23.2c.6-.8.4-2.2-.4-2.8C26.6 23.2 22 20 22 20v-9c0-1.2-.8-2-2-2s-2 .8-2 2v10c0 .6.4 1.2.8 1.6 1.8 1.2 5.2 3.8 7 5 1 .6 2.2.4 2.8-.4Z"
      fill="#F69B5A"
    />
  </svg>
);

export default ProcessingStatusIcon;
