import { FC } from 'react';
import { useRequestLLAQuery } from 'store/services/pageLlaAPI';
import { FlowTypes } from 'store/constants';
import ModulorLoader from 'Modulor/components/ModulorLoader';
import { SubmitFormWrapper } from 'Modulor/components/SubmitForm/SubmitFormWrapper';

export const PageLLA:FC<unknown> = () => {
  const responseLLAResult = useRequestLLAQuery();
  const { isFetching, isError, data } = responseLLAResult;

  if (isFetching) {
    return <ModulorLoader />;
  }

  if (isError) {
    return null;
  }

  return (
    <div className="lla-container">
      <SubmitFormWrapper
        redirectForm={data || {}}
        paymentType={FlowTypes.deposit}
        isLLA
      />
    </div>
  );
};

export default PageLLA;
