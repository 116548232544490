import { getWidgetLocation } from 'utils/index';
import { RenderCategory } from 'store/models';

export const getCategoryByPathname = (pathname: string): RenderCategory | null => {
  const {
    isDeposit,
    isDepositForm,
    isWithdrawal,
    isWithdrawalForm,
    isSuccessPage,
    isProcessingPage,
    isErrorPage,
    isWithdrawalCardList,
  } = getWidgetLocation(pathname);

  if (isSuccessPage || isProcessingPage || isErrorPage || isWithdrawalCardList) {
    return null;
  }

  const categories = [
    RenderCategory.depositForm,
    RenderCategory.depositMethodList,
    RenderCategory.withdrawalForm,
    RenderCategory.withdrawalMethodList,
  ];
  const index = [
    isDepositForm,
    isDeposit,
    isWithdrawalForm,
    isWithdrawal,
  ].findIndex((flag) => flag);

  return index !== -1 ? categories[index] : null;
};
