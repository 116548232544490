import * as Sentry from '@sentry/react';

const MAX_PAYLOAD_SIZE_IN_BYTES = 8 * 1024;

const croppEventPayload = (event: Sentry.Event) => {
  const modifiedEvent = {
    ...event,
  };
  if (modifiedEvent.exception?.values?.some((value) => value.stacktrace?.frames)) {
    modifiedEvent.exception.values = modifiedEvent.exception.values.map((value) => {
      if (value.stacktrace?.frames) {
        const { frames, ...rest } = value.stacktrace;
        return {
          ...value, stacktrace: rest,
        };
      }
      return value;
    });
  }
  if (modifiedEvent.breadcrumbs) {
    modifiedEvent.breadcrumbs = modifiedEvent.breadcrumbs.slice(-5);
  }
  if (modifiedEvent.extra?.largeData) {
    const { largeData, ...rest } = modifiedEvent.extra;
    modifiedEvent.extra = rest;
  }
  return modifiedEvent;
};

const prepareSentryErrorPayload = (event: Sentry.Event) => {
  const eventString = JSON.stringify(event);
  const eventSizeInBytes = new TextEncoder().encode(eventString).length;

  if (eventSizeInBytes > MAX_PAYLOAD_SIZE_IN_BYTES) {
    return croppEventPayload(event);
  }
  return event;
};

export const initSentry = (dsn: string): void => (
  Sentry.init({
    dsn,
    debug: true,
    tracesSampleRate: 0.9,
    maxBreadcrumbs: 20,
    beforeSend: prepareSentryErrorPayload,
  })
);
