import { isEmpty } from 'ramda';
import { CustomError } from 'store/models';
import { ErrorType } from 'store/models/errors';

export const getErrorMessage = (errorObject = {}): ErrorType => {
  if (isEmpty(errorObject)) {
    return {
      errorCode: '',
    };
  }

  const keys: string[] = Object.keys(errorObject as CustomError) || [];
  const errorObjectName: string = keys.includes('data') ? 'data' : 'error';
  const errorCode = `${(errorObject as CustomError)[errorObjectName]?.error_code || ''}`;
  const errorMessage = ((errorObject as CustomError)[errorObjectName]?.message
    || (errorObject as CustomError)[errorObjectName]?.error_message
    || ((errorObject as CustomError)[errorObjectName]?.errorMessage
    || ((errorObject as CustomError)?.errorMessage)));
  let errorAsString = '';

  if (typeof errorObject === 'string') {
    errorAsString = errorObject;
  }

  return {
    message: (errorMessage || errorAsString || '') as string,
    errorCode,
  };
};

export const setErrorIfNoValue = (fieldName: string) => new Error(`Error: no ${fieldName} for creating request`);
