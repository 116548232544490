import { FC } from 'react';
import { Content, Image, ListCell, ListView, Radio, RadioGroup } from '@modulor/react';
import { countryCodeList } from 'assets/countryCodeConfig';
import { countryDialCode } from 'assets/countryDialCode';

interface CountrySelectorProps {
  setLocalCountryCode: (value: string) => void;
  countryCode: string;
}

const getOptionLabel = (value: string) => {
  const { phonecode = '', nicename = '' } = countryDialCode.find((option) => option.iso === value.toUpperCase()) || {};
  return phonecode && nicename ? `+${phonecode} ${nicename}` : value;
};

export const CountrySelector: FC<CountrySelectorProps> = ({ setLocalCountryCode, countryCode }) => (
  <ListView>
    <RadioGroup onChange={(value) => setLocalCountryCode(`${value}`)}>
      {countryCodeList.map((code) => (
        <ListCell
          text={getOptionLabel(code)}
          key={code}
          icon={(
            <Image
              name={code.toLowerCase()}
              alt={code}
              src=""
            />
          )}
          content={(
            <Content>
              <Radio
                value={code}
                checked={code === countryCode}
              />
            </Content>
          )}
        />
      ))}
    </RadioGroup>
  </ListView>
);
