import { AnalyticsGlobalContext } from 'big-brother';
import { EventAnalytics } from './EventAnalytics';
import { AnalyticsEventPayload, SelfDescribingJson } from './types';
import { LoggerProcessor } from './LoggerProcessor';

class Clickstream {
  public readonly appId: string;

  // eslint-disable-next-line no-use-before-define
  private static instance: Clickstream;

  private analytics: EventAnalytics & {crossdomainId?: string} | null;

  private logger: LoggerProcessor | null;

  private constructor() {
    this.appId = 'cashier';
    this.analytics = null;
    this.logger = null;
  }

  public static use() {
    if (!Clickstream.instance) {
      Clickstream.instance = new Clickstream();
    }
    return Clickstream.instance;
  }

  public getIsSetup() {
    return Boolean(this.analytics);
  }

  public setup(eventAnalytics: EventAnalytics, loggerProcessor: LoggerProcessor) {
    this.analytics = eventAnalytics;
    this.logger = loggerProcessor;
  }

  public push(event: AnalyticsEventPayload, context: Array<SelfDescribingJson> = []) {
    this.analytics?.push(event, context);
    this.logger?.log(
      'push',
      'analytics:',
      this.analytics,
      '\n',
      'data:',
      event.data,
      '\n',
      'context:',
      JSON.stringify(context),
      '\n',
      'schema:',
      event.schema,
    );
  }

  public pageView() {
    this.analytics?.pageView();
    this.logger?.log('pageView');
  }

  public updateContext(data: AnalyticsGlobalContext) {
    this.analytics?.updateContext(data);
    this.logger?.log('updateContext', 'data:', data);
  }
}

export { Clickstream };
