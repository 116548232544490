/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { WIDGET_API, setReqHeaders } from 'utils';
import {
  PayHubCashierContractsCashierLLAResultResponse,
  PayHubCashierContractsLLAStatusResponse,
  PayHubWidgetContractsSubmitPaymentResponse,
} from '../models';
import { formatInteraction } from '../formatters';

export const pageLllaAPI = createApi({
  reducerPath: 'pageLLARequest',
  baseQuery: fetchBaseQuery({
    baseUrl: WIDGET_API,
    prepareHeaders(headers) {
      const arr = window.location.href.split('/');
      const session = arr[arr.length - 1];
      const sessionId = session.split('?')[0];
      setReqHeaders(headers, sessionId);
    },
  }),
  endpoints(builder) {
    return {
      requestLLA: builder.query<PayHubWidgetContractsSubmitPaymentResponse, void>({
        query: () => ({
          url: 'payments/interaction',
          method: 'GET',
        }),
        transformResponse: (response: PayHubCashierContractsCashierLLAResultResponse) => (formatInteraction(response)),
      }),
      requestLLAStatus: builder.query<PayHubCashierContractsLLAStatusResponse, void>({
        query: () => ({
          url: 'payments/status',
          method: 'GET',
        }),
      }),
    };
  },
});

export const {
  useRequestLLAQuery,
  useLazyRequestLLAStatusQuery,
} = pageLllaAPI;

export default pageLllaAPI.reducer;
