import {
  isDesktop,
  IOSView,
  AndroidView,
  isWinPhone,
} from 'react-device-detect';
import { appChannelTypes } from 'store/constants';

export const getAppChannel = (appChannelFromUrl: appChannelTypes) => {
  if (appChannelFromUrl) return appChannelFromUrl;
  if (isDesktop) {
    return appChannelTypes.web;
  }
  if (IOSView) return appChannelTypes.ios;
  if (AndroidView) return appChannelTypes.android;
  if (isWinPhone) return appChannelTypes.windows_phone;

  return appChannelTypes.unknown;
};
