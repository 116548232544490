import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlatStringObject } from '../../commonTypes';
import { Parameter } from '../models';

type OTFFieldNames = {
  requisiteFieldName: string;
  codeFieldName: string;
};
interface OTPSlice {
  isOTPEnabled: boolean;
  amount: string;
  payGroupId: number;
  requiredFields: Parameter[];
  fields: FlatStringObject;
  otpFieldsData: FlatStringObject;
  submitFieldsData: FlatStringObject;
  currency: string;
  requisiteField: Parameter | null;
  otpFieldNames: OTFFieldNames;
}

const initialState: OTPSlice = {
  isOTPEnabled: false,
  otpFieldNames: {
    requisiteFieldName: '',
    codeFieldName: '',
  },
  amount: '0',
  payGroupId: 0,
  requiredFields: [],
  fields: {},
  otpFieldsData: {},
  submitFieldsData: {},
  currency: '',
  requisiteField: null,
};

export const otpSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    toggleOTPMode: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOTPEnabled: action.payload,
    }),
    setOTPFieldNames: (state, action: PayloadAction<OTFFieldNames>) => ({
      ...state,
      otpFieldNames: {
        requisiteFieldName: action.payload.requisiteFieldName,
        codeFieldName: action.payload.codeFieldName,
      },
    }),
    setOTPRequiredFields: (state, action: PayloadAction<Parameter[]>) => ({
      ...state,
      requiredFields: action.payload,
    }),
    setOTPFields: (state, action: PayloadAction<FlatStringObject>) => ({
      ...state,
      fields: action.payload,
    }),
    setOTPFieldsData: (state, action: PayloadAction<FlatStringObject>) => ({
      ...state,
      otpFieldsData: action.payload,
    }),
    setOTPSubmitFieldsData: (state, action: PayloadAction<FlatStringObject>) => ({
      ...state,
      submitFieldsData: action.payload,
    }),
    setRequisiteField: (state, action: PayloadAction<Parameter>) => ({
      ...state,
      requisiteField: action.payload,
    }),
  },
});

export const isOTPEnabledSelector = (state: { otp: OTPSlice }) => state
  .otp.isOTPEnabled;
export const OTPFieldNamesSelector = (state: { otp: OTPSlice }) => state
  .otp.otpFieldNames;
export const OTPRequisiteSelector = (state: { otp: OTPSlice }) => state
  .otp.requisiteField;
export const OTPRequiredFieldsSelector = (state: { otp: OTPSlice }) => state
  .otp.requiredFields;
export const OTPFieldsSelector = (state: { otp: OTPSlice }) => state
  .otp.fields;
export const OTPFieldsDataSelector = (state: { otp: OTPSlice }) => state
  .otp.otpFieldsData;
export const OTPSubmitFieldsDataSelector = (state: { otp: OTPSlice }) => state
  .otp.submitFieldsData;

export const {
  toggleOTPMode,
  setOTPFieldNames,
  setOTPRequiredFields,
  setOTPFields,
  setRequisiteField,
  setOTPFieldsData,
  setOTPSubmitFieldsData,
} = otpSlice.actions;
export default otpSlice.reducer;
