import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface CheckoutState {
  cardFieldName: string;
}

export const initialState: CheckoutState = {
  cardFieldName: '',
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setCardFieldName(state, action: PayloadAction<string>) {
      return {
        ...state,
        cardFieldName: action.payload,
      };
    },
  },
});

export const cardFieldNameSelector = ({ checkout }: { checkout: CheckoutState }) => checkout.cardFieldName;

export const {
  setCardFieldName,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
