import { AbstractSubmitStrategy } from './AbstractSubmitStrategy';
import { SubmitStrategyHandleProps } from './models';

export class SubmitHandler {
  private strategy: AbstractSubmitStrategy;

  constructor(strategy: AbstractSubmitStrategy) {
    this.strategy = strategy;
  }

  public handle(props: SubmitStrategyHandleProps):void {
    this.strategy.handle(props);
  }
}
