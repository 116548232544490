import React from 'react';
import { RequestFields } from './SubmitForm';

interface Props {
  requestFields?: RequestFields;
}

const HiddenFields = (props: Props):JSX.Element | null => {
  const {
    requestFields: formFields,
  } = props;

  if (!formFields) {
    return null;
  }

  const fields = Object.keys(formFields);

  return (
    <>
      {fields.map((item) => (
        <input
          key={`${item}hidden`}
          type="hidden"
          name={item}
          value={formFields[item] as string}
        />
      ))}
    </>
  );
};

export default HiddenFields;
