import { TFunction } from 'commonTypes';
import { MerchantUserFeeResponce } from 'store/models';
import { NAMESPACES } from '../services/constants';

export const getMerchantUserFeeTranslation = (fee: MerchantUserFeeResponce | string, t: TFunction) => {
  const isStringFee = typeof fee === 'string';
  const percentageValue = isStringFee ? fee.replace('%', '') : fee.percentage?.toString() || '';
  const percentageTranslation = percentageValue ? t(`${NAMESPACES.PW_KEYS}:PH.FEE.MESSAGE`, {
    percentage: `${percentageValue}`,
  }) : '';
  const fixedFee = !isStringFee && fee.fix ? ` + ${fee.fix}` : '';

  return `${percentageTranslation}${fixedFee}`;
};
