import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { processCarouselData, UseCarouselProps } from 'Modulor/Carousel/utils';
import { useNavigateWithSearch } from '../Modulor/hooks';

export const useCarousel = (props: UseCarouselProps) => {
  const {
    methods,
    initCarouselMethods,
    methodIdToRedirect,
    isCarouselViewChanged,
    isInitialCarouselLoad,
    flowType,
    skip,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigateWithSearch();
  const process = processCarouselData(flowType, dispatch, navigate);

  useEffect(() => {
    const skipProcessCarousel = skip
        || isInitialCarouselLoad
        || !(methods?.length > 1 && initCarouselMethods?.length && !isCarouselViewChanged);

    if (skipProcessCarousel) {
      return;
    }

    process(props);
  }, [
    initCarouselMethods,
    methodIdToRedirect,
    isInitialCarouselLoad,
    skip,
  ]);
};
