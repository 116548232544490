/* eslint-disable camelcase */
import { WithdrawalRejectRequestResponse, WithdrawalRejectRequestStatus } from '../models';

export const transformWithdrawalRejectRequestResponse = (response?: WithdrawalRejectRequestResponse) => {
  if (!response) {
    return null;
  }

  const {
    success_amount,
    fail_amount,
  } = response || {};

  const status = !success_amount ? WithdrawalRejectRequestStatus.failed : WithdrawalRejectRequestStatus.success;

  return {
    status,
    successAmount: success_amount,
    failAmount: fail_amount,
  };
};
