/* eslint-disable implicit-arrow-linebreak */
import { useState, useEffect, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { Button, CodeField, Typography } from '@modulor/react';
import { RequisiteTypes, FlowTypes } from 'store/constants';
import { FlatStringObject, SubmitErrorAndResultType } from 'commonTypes';
import { amountSelector } from 'store/slices/global';
import { convertValueToFormat } from 'utils';
import {
  PayHubCashierContractsTrustedContactResponse as Trusted,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
} from 'store/models';
import { useVerifyCodeMutation } from 'store/services/accountManagementAPI';
import { useLazySubmitWithdrawalQuery } from 'store/services/withdrawalAPI';
import { userVerificationDataSelector } from 'store/slices/withdrawal';
import { VerificationData } from 'store/formatters';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamPayGroupCashierContext,
  getClickStreamEvent,
} from 'services/clickstream';
import { SubmitFormWrapper } from 'Modulor/components/SubmitForm/SubmitFormWrapper';
import {
  useGetPayGroupId,
  useFormContainerHook,
  useFrameChangeHeight,
  useVisualViewportSubmitButton,
} from 'Modulor/hooks';
import { useTranslation } from 'react-i18next';
import { currentDepositMethodCurrencySelector } from 'store/slices/deposit';
import { NAMESPACES } from 'services/constants';
import { formRequestBody } from '../../payUtils';
import { CodeReceiver } from './CodeReceiver';
import { isIncorrectCode } from '../../OTP/utils';

import './VerifyPage.scss';

const CODE_SIZE = 6;
const ERROR_CODE_MAX_REQUEST = '7';

export const getUserVerificationField = (userVerificationData: VerificationData) => {
  const fieldName = userVerificationData?.field?.name || ExtendedTypes.CreditCard;
  const fieldValue = userVerificationData.card
    ? convertValueToFormat(
      userVerificationData.card,
      userVerificationData?.field?.dataType,
    ) : '';
  return ({
    [fieldName]: fieldValue,
  });
};

export const VerifyAccountPage = () => {
  const [code, setCode] = useState<string[]>([]);
  const [hasIncorrectCode, setValidationError] = useState<boolean>(false);
  const [otpErrorCode, setOtpErrorCode] = useState<string>();
  const [contactType, switchContactType] = useState<string>(RequisiteTypes.phone);
  const [submitGeneralError, setSubmitGeneralError] = useState<string>('');
  const [confirmCodeAttempt, setConfirmCodeAttempt] = useState<number>(1);

  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(FlowTypes.withdrawal);
  const payGroupContext = getClickStreamPayGroupCashierContext();

  const { t } = useTranslation();
  const [
    verifyCodeTrigger, {
      isLoading: verifyCodeResultIsLoading,
      data: verifyCodeResultData,
    },
  ] = useVerifyCodeMutation();
  const currency = useAppSelector(currentDepositMethodCurrencySelector);
  const amount = useAppSelector(amountSelector);
  const payGroupId = useGetPayGroupId();
  const [submitWithdrawalTrigger, submitResult] = useLazySubmitWithdrawalQuery();
  const { error, data } = submitResult as SubmitErrorAndResultType;
  useFrameChangeHeight(true);
  const userVerificationData = useAppSelector(userVerificationDataSelector);
  const attemptsLeft = verifyCodeResultData?.attempts_left || undefined;

  const hasOnlyOneTrustedContact = userVerificationData?.trustedContacts?.length === 1;
  const contactValue = userVerificationData?.trustedContacts?.find((option: Trusted) =>
    option.type === contactType)?.value;
  const codeValue = code.join('');
  const incorrectCodeErrorMessage = useMemo(() => {
    const keys:FlatStringObject = {
      7: t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.STATUS_PAGE.LIMIT_VERIFICATION_CODE.TITLE`),
      other: t(`${NAMESPACES.PW_KEYS}:PH.INFO.VERIFICATION_CODE_INCORRECT`, {
        count: attemptsLeft,
      }),
    };
    return otpErrorCode && (keys[otpErrorCode] || keys.other);
  }, [otpErrorCode,
    attemptsLeft]);

  const sendCloseClickstreamEvent = () => {
    const event = getClickStreamEvent.cashier_payout_back_button_click;
    clickstream.push(event, [cashierContext,
      payGroupContext]);
  };

  useEffect(() => {
    document.addEventListener('СlickstreamBack', sendCloseClickstreamEvent);
    const event = getClickStreamEvent.cashier_payout_verification_page_open;
    clickstream.push(event, [cashierContext,
      payGroupContext]);
    if (userVerificationData?.trustedContacts?.length === 1 && userVerificationData.trustedContacts[0]?.type) {
      switchContactType(userVerificationData.trustedContacts[0]?.type);
    }

    return () => {
      document.removeEventListener('СlickstreamBack', sendCloseClickstreamEvent);
    };
  }, []);

  useEffect(() => {
    setValidationError(isIncorrectCode(verifyCodeResultData?.success));
    if (verifyCodeResultData?.error_code) {
      setOtpErrorCode(verifyCodeResultData?.error_code.toString());
    }
  }, [verifyCodeResultData]);

  useFormContainerHook({
    error,
    setSubmitGeneralError,
  });

  const handleSwitchContactType = () => {
    const requisiteType = contactType === RequisiteTypes.phone ? RequisiteTypes.email : RequisiteTypes.phone;
    const event = getClickStreamEvent.cashier_payout_change_confirmation_contact_option_click(requisiteType);
    clickstream.push(event, [cashierContext,
      payGroupContext]);
    switchContactType(requisiteType);
  };

  const checkCode = async () => {
    const event = getClickStreamEvent.cashier_payout_otp_confirm_button_click(confirmCodeAttempt);
    clickstream.push(event, [cashierContext,
      payGroupContext]);
    setConfirmCodeAttempt((prev: number) => prev + 1);
    if (!codeValue.length) {
      return;
    }
    await verifyCodeTrigger(codeValue);
    if (verifyCodeResultData?.success) {
      const clEvent = getClickStreamEvent.cashier_payout_account_verification_result;
      clickstream.push(clEvent, [cashierContext,
        payGroupContext]);
      const fieldName = userVerificationData?.field?.name || ExtendedTypes.CreditCard;
      const fields = userVerificationData?.card ? {
        [fieldName]: userVerificationData.card.toString(),
        // TODO: for alternative method else condition should be added
      } : {};
      const requestBody = formRequestBody({
        fields,
        currency,
        amount,
        isNewPayoutAccount: true,
      });

      submitWithdrawalTrigger({
        payGroupId,
        requestBody,
      });
    }
  };

  const handleCodeChange = (value: string[]) => {
    setValidationError(false);
    setCode(value);
  };

  const pendingSubmit = verifyCodeResultIsLoading || submitResult.isFetching;
  const shouldRequestNewOtp = hasIncorrectCode && otpErrorCode === ERROR_CODE_MAX_REQUEST;
  const isSubmitDisabled = (code.filter((value: string) => !!value).length !== CODE_SIZE)
    || pendingSubmit || shouldRequestNewOtp;
  const infoReceiveKindTranslation = contactType === RequisiteTypes.phone ? 'SMS' : 'EMAIL';

  const { formStyles } = useVisualViewportSubmitButton(isSubmitDisabled);

  if (!submitGeneralError && !!data) {
    return (
      <SubmitFormWrapper
        paymentType={FlowTypes.withdrawal}
        redirectForm={data}
      />
    );
  }

  return (
    <form className="verification-form">
      <Typography
        className="contact-value"
        variant="title-1-semibold"
      >
        { contactValue }
      </Typography>
      <Typography
        className="logical-block method-kind-info"
        variant="body-regular"
      >
        {t(`${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.INFO.VERIFICATION_CODE_${infoReceiveKindTranslation}`)}
      </Typography>
      <div className="code-fields">
        <CodeField
          size={CODE_SIZE}
          maxInputSize={1}
          onComplete={setCode}
          value={code}
          onChange={(value) => handleCodeChange(value as string[])}
          error={hasIncorrectCode ? incorrectCodeErrorMessage : ''}
        />
      </div>
      <div className="logical-block">
        <CodeReceiver
          switchContactType={handleSwitchContactType}
          hasOnlyOneTrustedContact={hasOnlyOneTrustedContact}
          contactType={contactType}
        />
      </div>
      <div
        className="verification-form__footer"
        style={formStyles}
      >
        <Button
          onClick={checkCode}
          disabled={isSubmitDisabled}
        >
          {t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.CONFIRM`)}
        </Button>
      </div>
    </form>
  );
};
