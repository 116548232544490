/* eslint-disable no-console */
import React, { Component, ReactNode } from 'react';
import IntegrationCommunication from 'services/integration-communication';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log('error ', error);
    console.log('errorInfo ', errorInfo);
    IntegrationCommunication.sendMessage({
      id: 'reInitCashier',
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return null;
    }
    return children;
  }
}

export default ErrorBoundary;
