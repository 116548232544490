/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
import {
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType,
  PayHubAdminPanelDomainEnumsParameterDataType,
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType as ActionTypes,
  PayHubCashierContractsInitParamsResponse,
  PayHubCashierContractsPaymentGroupInfo as GroupInfo,
  PayHubCashierContractsPaymentsInitPaymentGroupsResponse as InitSuccess,
  PayHubWidgetContractsPayoutsSelectPayoutGroupResponse,
  PayoutProcessMethodInfo,
} from 'store/models';
import { getInitialTheme, getViewData, parseQuery, stringToInt, transformContentUrl } from 'utils';
import { DEFAULT_EMPTY_CURRENCY_ALPHA3, noMethodsError } from '../constants';
import {
  formNotificationData,
  getCurrency,
  getEnabled,
  getMethodsTypes,
  getMinLimit,
  sortByArrangement,
  getIsLimitReached,
  GeneralInfo,
  UIPayout,
  UIPayoutGroups,
  getMaxAmount, ViewDataType,
} from './commonPayments';

// eslint-disable-next-line sonarjs/cognitive-complexity
const formWithdrawalMethod = (generalInfo: GeneralInfo) => (pmMethod: GroupInfo): UIPayoutGroups => {
  const {
    isLimitReached,
    generalViewData,
    disabledByWager,
  } = generalInfo;
  const currentCurrency = getCurrency(pmMethod);
  const min = getMinLimit(currentCurrency);
  const max = getMaxAmount(currentCurrency);
  const infoLink = pmMethod.info;

  return {
    arrangement: pmMethod.arrangement || 0,
    id: pmMethod.id || 0,
    name: pmMethod.short_name?.trim() || '',
    displayName: pmMethod.public_name || '',
    isPopular: Boolean(pmMethod.popular),
    logoUri: pmMethod.logo || '',
    nativePayType: pmMethod.native_pay_type || '',
    paymentType: '',
    requiredFields: [],
    currencyId: currentCurrency.currency?.currency_id || 0,
    currencyAlpha3: currentCurrency.currency?.alpha3 || '',
    descriptionUrl: infoLink
      ? transformContentUrl(infoLink)
      : '',
    limits: {
      min,
      max,
    },
    amountRegExpFe: '',
    viewData: [],
    payGroupActionType: pmMethod.action_type || '',
    merchantUserFee: pmMethod.merchant_user_fee,
    fee: currentCurrency.fee || '',
    processingTimeTranslationKey: currentCurrency.processing_time_translation_key || undefined,
    showProcessingTime: currentCurrency.show_processing_time || false,
    isSelfTarget: pmMethod.info_in_iframe || false,
    enabled: getEnabled(isLimitReached, min, pmMethod, generalViewData, disabledByWager),
    restrictedUntil: pmMethod?.restricted_until,
    pay_method_type: pmMethod.pay_method_type || '',
    last_used: pmMethod.last_used || false,
    autoSubmit: pmMethod.auto_submit || false,
    isCheckout: false,
    isLoading: true,
    showInCarousel: pmMethod.show_in_carousel || false,
  };
};

export const getMethodForAutoRedirectWithdrawal = (
  props: {
      methods: UIPayoutGroups[];
      initCarouselMethods?: UIPayoutGroups[];
      isInitialCarouselLoad?: boolean;
    },
): UIPayoutGroups | undefined => {
  const {
    methods = [],
    initCarouselMethods,
    isInitialCarouselLoad,
  } = props;
  const [firstMethod] = methods;

  if (!firstMethod) {
    return undefined;
  }

  if (methods.length === 1 && firstMethod.enabled && firstMethod.payGroupActionType !== ActionTypes.NestedList) {
    return firstMethod;
  }

  const { pay_group = '' } = parseQuery(window.location.search);
  const methodFromQuery = methods.find(({ id, name }) => id === stringToInt(pay_group as string) || name === pay_group);

  if (methodFromQuery && methodFromQuery.enabled && methodFromQuery.payGroupActionType !== ActionTypes.NestedList) {
    return methodFromQuery;
  }

  if (initCarouselMethods && initCarouselMethods.length > 1 && !isInitialCarouselLoad && initCarouselMethods[0].payGroupActionType !== ActionTypes.NestedList) {
    return initCarouselMethods[0];
  }

  return undefined;
};

export const formWithdrawalGroups = (payload: InitSuccess): UIPayout => {
  const {
    view_data,
    payment_groups = null,
    payout_groups = null,
    cashier_settings,
    tax = {},
    cashier_features = [],
    merchant_url,
    merchant_success_url,
    merchant_fail_url,
    merchant_processing_url,
    merchant_redirect_type,
    user_balance = 0,
  } = payload;
  const isWagerAmountExist = view_data?.some((item) => item.params?.wager_amount);
  const initialTheme = getInitialTheme();
  const receivedGroups = payment_groups || payout_groups;
  const groups = Array.isArray(receivedGroups) ? receivedGroups : [];
  const preparedViewData = (groups?.length ? view_data : [
    noMethodsError,
    ...view_data || [],
  ]) as ViewDataType;
  const isLimitReached = getIsLimitReached(preparedViewData);
  const generalSettings: GeneralInfo = {
    isLimitReached,
    generalViewData: preparedViewData,
    // https://gr8-tech.atlassian.net/browse/BILL-14691
    disabledByWager: !!(isWagerAmountExist && initialTheme === 'brand22'),
  };
  const methods = groups.sort(sortByArrangement).map(formWithdrawalMethod(generalSettings));
  const initCarouselMethods = methods.filter(({
    showInCarousel,
    enabled,
  }) => showInCarousel && enabled);
  const currencyId = (methods && methods[0]?.currencyId) || null;
  const currencyAlpha3 = (methods && methods[0]?.currencyAlpha3) || null;
  const methodsTypes = getMethodsTypes(methods);

  return {
    tax,
    methodsTypes,
    isReady: true,
    canAutoRedirect: true,
    isRequestPending: false,
    submitPaymentReady: false,
    currencyId,
    currencyAlpha3,
    widgetSettings: cashier_settings,
    methods,
    initCarouselMethods,
    viewData: formNotificationData({
      viewData: preparedViewData,
      isGeneral: true,
    }),
    merchantUrl: merchant_url,
    merchantSuccessUrl: merchant_success_url,
    merchantFailUrl: merchant_fail_url,
    merchantProcessingUrl: merchant_processing_url,
    merchantRedirectType: merchant_redirect_type,
    userBalance: user_balance,
    growthbookFeatures: cashier_features,
  };
};

export const formWithdrawalRequiredFields = (
  parameters: PayHubCashierContractsInitParamsResponse[] | null | undefined,
// eslint-disable-next-line sonarjs/cognitive-complexity
): Parameter[] | [] => {
  if (!parameters || parameters.length === 0) {
    return [];
  }

  return parameters.map(({
    name,
    type,
    extended_type,
    data_type,
    regexp,
    label,
    place_holder,
    place_holder_empty_list,
    place_holder_empty_list_error,
    is_required,
    is_otp_requisites,
    options,
    user_requisite,
    user_requisite_type_id,
    verify_new_payout_account,
  }: PayHubCashierContractsInitParamsResponse): Parameter => {
    const formattedOptions = options && options.length ? options.map((option) => ({
      ...option,
      selected: option.is_selected || false,
      name: option.label || '',
      value: option.value || '',
    })) : undefined;

    return {
      name: name || '',
      key: name || JSON.stringify(label),
      type: type || PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamType.Input,
      extendedType: extended_type,
      dataType: data_type || PayHubAdminPanelDomainEnumsParameterDataType.String,
      regexp: regexp || undefined,
      label,
      placeHolder: place_holder,
      placeHolderEmptyList: place_holder_empty_list,
      placeHolderEmptyListError: place_holder_empty_list_error,
      isRequired: is_required,
      isOtpRequisites: is_otp_requisites,
      options: formattedOptions,
      userRequisite: user_requisite || null,
      verifyNewPayoutAccount: verify_new_payout_account,
      userRequisiteTypeId: user_requisite_type_id || null,
    };
  });
};

export const formWithdrawalMethodInfo = (
  response: PayHubWidgetContractsPayoutsSelectPayoutGroupResponse,
  requiredFieldsFormatter: (params: PayHubCashierContractsInitParamsResponse[] | null | undefined) => Parameter[],
  payGroupId: number,
): PayoutProcessMethodInfo => {
  const {
    currency = {},
    limits = {},
    amount_blocks,
    parameters,
    view_data,
    processing_currency,
    show_processing_currency,
    amount_reg_exp_fe,
    auto_submit,
    payout_account_verification_blocked,
    payout_account_verification_blocked_until = '',
    support_url,
    trusted_contacts,
    allow_new_payout_account,
    merchant_user_fee,
    processing_currency_rate,
    info,
    processing_time_translation_key,
    fee,
  } = response;
  const {
    currency_id,
    alpha3,
  } = currency;
  const {
    min_amount,
    max_amount,
    max_recurring_amount,
  } = limits;

  const limitsObject = {
    min: min_amount || 0,
    max: max_amount || undefined,
    maxRecurrentAmount: max_recurring_amount || undefined,
  };
  const requiredFields = requiredFieldsFormatter(parameters);
  const { additionalViewData, viewData } = getViewData(view_data);

  return {
    showProcessingTime: show_processing_currency as boolean,
    currencyAlpha3: alpha3 || DEFAULT_EMPTY_CURRENCY_ALPHA3,
    limits: limitsObject,
    currencyId: currency_id || 0,
    requiredFields,
    additionalViewData,
    // TODO: double check
    viewData: formNotificationData({
      viewData,
      payGroupId,
    }),
    processingCurrency: processing_currency,
    amountBlocks: amount_blocks,
    amountRegExpFe: amount_reg_exp_fe || undefined,
    autoSubmit: auto_submit,
    payoutAccountVerifBlocked: payout_account_verification_blocked,
    payoutAccountVerifBlockedUntil: payout_account_verification_blocked_until,
    supportUrl: support_url,
    trustedContacts: trusted_contacts,
    allowNewPayoutAccount: allow_new_payout_account,
    merchantUserFee: merchant_user_fee,
    processingCurrencyRate: processing_currency_rate || undefined,
    info: info || undefined,
    processingTimeTranslationKey: processing_time_translation_key || undefined,
    fee: fee || undefined,
  };
};
