import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { errorPopUpState, ErrorType } from '../models';

export const initialState: errorPopUpState = {
  isOpen: false,
  error: {
    errorCode: '0',
  },
};

const errorPopUpSlice = createSlice({
  name: 'errorPopUp',
  initialState,
  reducers: {
    showErrorPopUp(state, action: PayloadAction<ErrorType>) {
      return {
        error: action.payload,
        isOpen: true,
      };
    },
    closeErrorPopUp() {
      return {
        error: {
          errorCode: '0',
          message: '',
        },
        isOpen: false,
      };
    },
  },
});

export const isErrorPopUpOpenedSelector = ({
  errorPopUp,
}: { errorPopUp: errorPopUpState }): boolean => errorPopUp.isOpen;

export const errorPopUpCodeSelector = ({
  errorPopUp,
}: { errorPopUp: errorPopUpState }): ErrorType => errorPopUp.error;

export const {
  showErrorPopUp,
  closeErrorPopUp,
} = errorPopUpSlice.actions;

export default errorPopUpSlice.reducer;
