import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  PayHubCashierContractsCashierDeclinePageSettingsResponse,
  PayHubCashierContractsPaymentSessionStateResponse,
} from '../models';

export const initialState: PayHubCashierContractsPaymentSessionStateResponse = {
  session_id: '',
  action: undefined,
  result_status: undefined,
  session_status: undefined,
  transaction_status: undefined,
  experiment_user_group: undefined,
  decline_page_settings: undefined,
  error_fatal: undefined,
  success_url: undefined,
  fail_url: undefined,
  processing_url: undefined,
  merchant_url: undefined,
};

const paySessionStateSlice = createSlice({
  name: 'paySessionState',
  initialState,
  reducers: {
    setPaySessionState: (
      state,
      { payload }: PayloadAction<PayHubCashierContractsPaymentSessionStateResponse>,
    ) => ({
      ...state,
      ...payload,
    }),
    setDefaultPaySessionState: (state) => ({
      ...initialState,
    }),
  },
});

export const paySessionStateSelector = ({
  paySessionState,
}: {
  paySessionState: PayHubCashierContractsPaymentSessionStateResponse;
}): PayHubCashierContractsPaymentSessionStateResponse => paySessionState;

export const declineReasonSelector = ({
  paySessionState,
}: {
  paySessionState: PayHubCashierContractsPaymentSessionStateResponse;
}): PayHubCashierContractsCashierDeclinePageSettingsResponse | undefined => paySessionState.decline_page_settings;

export const {
  setPaySessionState,
  setDefaultPaySessionState,
} = paySessionStateSlice.actions;

export default paySessionStateSlice.reducer;
