import { Logger } from '../Logger';

class LoggerProcessor {
  private instance: Logger;

  public constructor(loggerId: string) {
    this.instance = new Logger(loggerId);
  }

  // you can send a simple event using a schema
  public log = (id: string, ...args: unknown[]) => {
    this.instance.info(`[${id}]`, ...args);
  };
}

export { LoggerProcessor };
