/* eslint-disable react/no-danger */
import { FC } from 'react';
import { ListView, Icon, ListCell, Typography } from '@modulor/react';
import { getTranslationMessage } from 'utils';
import { PayHubCashierContractsViewDataResponse } from 'store/models';

import './AdditionalInfo.scss';
import { useTranslation } from 'react-i18next';

interface AdditionalInfoProps {
    additionalData?: PayHubCashierContractsViewDataResponse[] | null;
}

const AdditionalInfo: FC<AdditionalInfoProps> = ({ additionalData }) => {
  const { t } = useTranslation();
  if (!additionalData || additionalData.length === 0) {
    return null;
  }

  const styles: { [param: string]: string } = {
    '--list-cell-background': 'var(--background-secondary)',
    '--list-cell-left-subtitle': 'var(--caption-1-regular)',
  };

  return (
    <div className="additional-info-container">
      {additionalData.map((i) => {
        const {
          value,
          params,
          key,
        } = i;
        const message = getTranslationMessage({
          t,
          value,
          params,
        });
        return (
          <ListView key={key}>
            <ListCell
              style={styles}
              icon={(
                <Icon
                  name="info_circle_outlined"
                />
                  )}
              leftContent={(
                <Typography
                  key={key}
                  variant="caption-1-regular"
                  dangerouslySetInnerHTML={{
                    __html: message || '',
                  }}
                />
                  )}
            />
          </ListView>
        );
      })}
    </div>
  );
};

export default AdditionalInfo;
