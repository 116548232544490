import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { useAppSelector } from 'store/hooks';
import { betShopOptionsSelector, currentBetShopSelector } from 'store/slices/withdrawal';
import { BetShopsList } from './BetShopsList';

import './BetShops.scss';
import { SelectedBetShop } from './SelectedBetShop';

interface BetShopsFieldProps {
  setValue: UseFormSetValue<FieldValues>;
  name: string;
}

export const BetShopsField = ({
  setValue,
  name,
}: BetShopsFieldProps) => {
  const [displayList, setListOpened] = useState(false);

  const currentBetShop = useAppSelector(currentBetShopSelector);
  const betShopOptions = useAppSelector(betShopOptionsSelector);

  const onFieldClick = () => {
    setListOpened(true);
  };

  return (
    <>
      <SelectedBetShop
        onClick={onFieldClick}
        currentBetShop={currentBetShop}
      />
      <BetShopsList
        name={name}
        isOpen={displayList}
        setOpen={setListOpened}
        options={betShopOptions}
        setValue={setValue}
      />
    </>
  );
};
