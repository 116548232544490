import React from 'react';
import { Button, Content, ListCell, ListView } from '@modulor/react';

interface LoadedFileCellProps {
    uploadedFile: File | undefined;
    handleResetFile: () => void;
    isDisabled?: boolean;
}

const LoadedFileCell: React.FC<LoadedFileCellProps> = ({ uploadedFile, handleResetFile, isDisabled }) => (
  <ListView>
    <ListCell
      text={uploadedFile?.name}
      content={(
        <Content>
          <Button
            variant="secondary"
            icon="delete_outlined"
            onClick={handleResetFile}
            disabled={isDisabled}
          />
        </Content>
      )}
    />
  </ListView>
);

export default LoadedFileCell;
