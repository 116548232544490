import clx from 'classnames';
import { Typography, ListView, ListCell, Icon } from '@modulor/react';
import { PayHubCashierContractsParamOptionResponse } from 'store/models/APIModels';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';

interface SelectedBetShopProps {
  onClick: VoidFunction;
  currentBetShop?: PayHubCashierContractsParamOptionResponse;
}

export const SelectedBetShop = ({
  onClick,
  currentBetShop,
}: SelectedBetShopProps) => {
  const { t } = useTranslation();
  const label = currentBetShop?.label || t(`${NAMESPACES.PW_KEYS}:CASHIER.PH2.CITY`);

  return (
    <>
      <div>
        <Typography variant="subhead-regular">
          {t(`${NAMESPACES.PW_KEYS}:CASHIER.CASHBOX.TITLE`)}
        </Typography>
      </div>
      <div
        className="cell-wrapper"
        onClick={onClick}
      >
        <ListView>
          <ListCell
            text={label}
            className={clx({
              'has-selected-value': !!currentBetShop?.additional_data?.label,
            })}
            icon={(
              <Icon
                fill="icon-deposit"
                name="pin_outlined"
              />
                  )}
            subtitle={(
              <>
                <div>
                  <Typography variant="caption-1-regular">
                    {currentBetShop?.additional_data?.label}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption-1-regular">
                    {currentBetShop?.additional_data?.workingHours}
                  </Typography>
                </div>
              </>
              ) as unknown as string}
            content={(
              <div className="checkbox__in-list-cell">
                <Icon name="chevron_right" />
              </div>
              )}
          />
        </ListView>
      </div>
    </>
  );
};
