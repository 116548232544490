/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, Dispatch, SetStateAction, useLayoutEffect, useRef } from 'react';
import { useOnClickOutside } from 'Modulor/hooks';
import {
  BottomSheet,
  NavigationBar,
  Icon,
  RadioGroup,
  ListView,
  Typography,
  Modal,
} from '@modulor/react';
import { PayHubCashierContractsParamOptionResponse } from 'store/models';
import { currentBetShopSelector, setBetShopSelected } from 'store/slices/withdrawal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { getLayoutType } from 'utils/common';
import { DESKTOP_MODAL_MIN_HEIGHT, DESKTOP_MODAL_WIDTH, LayoutType } from 'utils/constants';
import { nanoid } from '@reduxjs/toolkit';
import IntegrationCommunication from 'services/integration-communication';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';
import { PreparedCells } from './types';
import { BetShopsListCell } from './BetShopsListCell';

interface BetShopsListProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  options?: PayHubCashierContractsParamOptionResponse[];
  setValue: UseFormSetValue<FieldValues>;
  name: string;
}

export const BetShopsList:FC<BetShopsListProps> = (props) => {
  const {
    isOpen,
    setOpen,
    options = [],
    setValue,
    name,
  } = props;

  const modalRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(modalRef, () => setOpen(false));

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentBetShop = useAppSelector(currentBetShopSelector);

  const [preparedCells, setpreparedCells] = useState<PreparedCells[]>([]);
  const [expandedCells, setExpandedCells] = useState<string[]>([]);

  const layoutType = getLayoutType();
  const isMobileLayout = layoutType === LayoutType.mobile;

  useEffect(() => {
    const updatedOptions: PreparedCells[] = options?.map((option) => ({
      ...option,
      key: nanoid(),
    }));

    setpreparedCells(updatedOptions);
  }, [options.length]);

  useEffect(() => {
    const betShopForExpand = preparedCells.find((option) => option.label === currentBetShop?.label);
    if (isOpen && currentBetShop?.value && betShopForExpand) {
      setExpandedCells([
        ...expandedCells,
        betShopForExpand.key,
      ]);
    }
    return () => setExpandedCells([]);
  }, [
    isOpen,
    currentBetShop,
    options,
  ]);

  useLayoutEffect(() => {
    if (isOpen && !isMobileLayout) {
      const { height = 350 } = window.document.getElementById('root')?.getBoundingClientRect() || {};
      setTimeout(() => {
        IntegrationCommunication.sendMessage({
          id: 'frameChangedHeight',
          value: height <= DESKTOP_MODAL_MIN_HEIGHT ? DESKTOP_MODAL_MIN_HEIGHT + 50 : height,
        });
      }, 0);
    }
  }, [isOpen,
    isMobileLayout,
  ]);

  const handleToggle = (value: boolean) => () => {
    setOpen(value);
  };

  const onSelectOption = (option: PayHubCashierContractsParamOptionResponse): void => {
    dispatch(setBetShopSelected(option));
    setValue(name, option.value);
    setOpen(false);
  };

  const handleBack = () => {
    setOpen(false);
  };

  const renderBetShopList = () => (
    <ListView className="list-wrapper">
      <RadioGroup onChange={() => {}}>
        {preparedCells?.map((cell) => (
          <BetShopsListCell
            key={cell.key}
            rootCell={cell}
            currentBetShop={currentBetShop}
            expandedCells={expandedCells}
            setExpandedCells={setExpandedCells}
            onSelectOption={onSelectOption}
          />
        ))}
      </RadioGroup>
    </ListView>
  );

  const renderHeader = () => (
    <div className="header">
      <Typography variant="title-2-semibold">{t(`${NAMESPACES.PW_KEYS}:CASHIER.CASHBOX.TITLE`)}</Typography>
      <div className="header-navigation">
        <NavigationBar
          transparent
          right={(
            <Icon
              name="close"
              onClick={handleBack}
            />
          )}
        />
      </div>
    </div>
  );

  return isMobileLayout ? (
    <BottomSheet
      open={isOpen}
      onShadowClick={handleToggle(false)}
      onSwipeDown={handleToggle(false)}
      header={renderHeader()}
    >
      {renderBetShopList()}
    </BottomSheet>
  )
    : (
      <Modal
        className="bet-shops-modal"
        ref={modalRef}
        openModal={isOpen}
        minDesktopModalHeight={DESKTOP_MODAL_MIN_HEIGHT}
        desktopModalWidth={DESKTOP_MODAL_WIDTH}
        header={renderHeader()}
      >
        {[renderBetShopList()]}
      </Modal>
    );
};
