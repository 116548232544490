import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ContentPageState {
  url: string;
  isOpen: boolean;
}

export const initialState: ContentPageState = {
  url: '',
  isOpen: false,
};

const contentPageSlice = createSlice({
  name: 'contentPage',
  initialState,
  reducers: {
    openContentPageUrl(state, action: PayloadAction<string>) {
      return {
        url: action.payload,
        isOpen: true,
      };
    },
    closeContentPageUrl() {
      return {
        url: '',
        isOpen: false,
      };
    },
  },
});

export const isContentPageOpenSelector = ({ contentPage }: { contentPage: ContentPageState }) => contentPage.isOpen;
export const contentPageUrlSelector = ({ contentPage }: { contentPage: ContentPageState }) => contentPage.url;

export const {
  openContentPageUrl,
  closeContentPageUrl,
} = contentPageSlice.actions;

export default contentPageSlice.reducer;
