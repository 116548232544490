import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayHubWidgetContractsUserCardResponse } from 'store/models';

export interface SelectedCardType extends PayHubWidgetContractsUserCardResponse {
  isNew?: boolean;
}
export interface CardState {
  isDeleteCardMode?: boolean;
  cardsList?: PayHubWidgetContractsUserCardResponse[];
  isCardsFetched?: boolean;
  selectedCard: SelectedCardType;
}

const initialState: CardState = {
  isDeleteCardMode: false,
  cardsList: [],
  isCardsFetched: false,
  selectedCard: {},
};

export const userCardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setSelectedCard: (state, action: PayloadAction<SelectedCardType>) => {
      if (!action.payload?.id) {
        return {
          ...state,
          selectedCard: {
            isNew: true,
          },
        };
      }
      return {
        ...state,
        selectedCard: {
          ...action.payload,
          isNew: false,
        },
      };
    },
    showDeleteCardMode: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isDeleteCardMode: action.payload,
    }),
    setCards: (state, action: PayloadAction<PayHubWidgetContractsUserCardResponse[]>) => ({
      ...state,
      cardsList: action.payload,
      isCardsFetched: true,
    }),
    removeCard: (state, action: PayloadAction<number>) => ({
      ...state,
      cardsList: state.cardsList?.filter((card) => card.id !== action.payload),
    }),
    cleanCardState: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const selectedCardSelector = ({ card }:
{ card: CardState }) => card;

export const {
  setSelectedCard,
  showDeleteCardMode,
  setCards,
  removeCard,
  cleanCardState,
} = userCardSlice.actions;

export default userCardSlice.reducer;
