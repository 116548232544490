import { softLocalStorageGet, softLocalStorageSet } from 'utils';

/* eslint-disable no-console */
const LOGGER = 'LOGGER';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const logOff = () => {};
const getLoggerEnabled = (loggerId: string) => {
  if (!loggerId) return false;
  const storageId = `${LOGGER}.${loggerId}`;
  const isLoggerEnabled = softLocalStorageGet(loggerId);
  if (isLoggerEnabled === null) {
    softLocalStorageSet(storageId, 'false');
  }

  return isLoggerEnabled === 'true';
};

export class Logger {
  readonly loggerId: string;

  readonly isLoggerEnabled : boolean;

  constructor(loggerId: string) {
    this.loggerId = loggerId;
    this.isLoggerEnabled = getLoggerEnabled(this.loggerId);
  }

  public info(...args: unknown[]) {
    if (!getLoggerEnabled(this.loggerId)) {
      logOff();
    } else {
      console.info(`%c[${this.loggerId}]`, 'color: DarkBlue', ...args);
    }
  }

  public error(...args: unknown[]) {
    if (!getLoggerEnabled(this.loggerId)) {
      logOff();
    } else {
      console.info(`%c[${this.loggerId}]`, 'color: red', ...args);
    }
  }
}
