import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { Button, Icon, Typography } from '@modulor/react';
import { Routes } from 'Modulor/router';
import {
  isAccVerifBlockedSelector,
  accountVerifBlockedUntilSelector,
  userVerificationDataSelector,
} from 'store/slices/withdrawal';
import { setHeadersNavIconRedirectPath, setIsStatusPageMode } from 'store/slices/global';
import IntegrationCommunication from 'services/integration-communication';
import { useFrameChangeHeight, useVisualViewportSubmitButton } from 'Modulor/hooks';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';

import './VerificationBlockedPage.scss';

type ErrorDataType = {
  title: string;
  subtitle: string;
  icon: string;
  btnText: string;
  btnIcon?: string;
  callback?: () => void;
};

const initialErrStateData = {
  title: '',
  subtitle: '',
  icon: '',
  btnText: '',
};

export const VerificationBlockedPage = () => {
  const [errorData, setErrorData] = useState<ErrorDataType>(initialErrStateData);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAccVerifBlocked = useAppSelector(isAccVerifBlockedSelector);
  const userVerificationData = useAppSelector(userVerificationDataSelector);
  const accountVerifBlockedUntil = useAppSelector(accountVerifBlockedUntilSelector);
  const blockedUntilText = accountVerifBlockedUntil ? new Date(accountVerifBlockedUntil) : new Date();
  const dateTimeInMs = blockedUntilText.getTime() - Date.now();
  const dateTimeInMin = Math.floor(dateTimeInMs / (1000 * 60));
  const { formStyles } = useVisualViewportSubmitButton();

  const ACCOUNT_VERIF_BLOCKED_ERROR_DATA = {
    title: t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.STATUS_PAGE.LIMIT_VERIFICATION_CODE.TITLE`),
    subtitle: blockedUntilText ? t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.STATUS_PAGE.LIMIT_VERIFICATION_CODE.BODY`, {
      time: dateTimeInMin.toLocaleString(),
    }) : '',
    icon: 'clear_outlined',
    btnText: t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.ACCEPT`),
  };

  const DEFAULT_ERROR_DATA = {
    title: t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.STATUS_PAGE.FAIL_VERIFICATION.TITLE`),
    subtitle: t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.STATUS_PAGE.FAIL_VERIFICATION_SUPPORT.BODY`),
    icon: 'error_circle_outlined',
    btnText: t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.ACCEPT`),
  };

  useFrameChangeHeight(true);

  useEffect(() => {
    dispatch(setIsStatusPageMode(true));
    return () => {
      dispatch(setIsStatusPageMode(false));
    };
  }, []);

  const onClickConfirm = () => {
    IntegrationCommunication.sendMessage({
      id: 'reInitCashier',
    });
  };

  const onClickSupport = (supportUrl: string): void => {
    IntegrationCommunication.sendMessage({
      id: 'redirectTo',
      value: supportUrl,
    });
  };

  useEffect(() => {
    const error: ErrorDataType = isAccVerifBlocked ? ACCOUNT_VERIF_BLOCKED_ERROR_DATA : DEFAULT_ERROR_DATA;
    if (userVerificationData?.supportUrl) {
      error.btnIcon = 'support_outlined';
      error.btnText = t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.SUPPORT`);
    }
    error.callback = () => (userVerificationData?.supportUrl
      ? onClickSupport(userVerificationData.supportUrl) : onClickConfirm());

    dispatch(setHeadersNavIconRedirectPath(`/${Routes.withdrawal}`));
    setErrorData(error);
  }, [isAccVerifBlocked]);

  return (
    <>
      <section
        className="status-wrapper"
      >
        <div className="status-icon-wrapper">
          <Icon
            name={errorData.icon}
            size={40}
            fill="text-error"
          />
        </div>
        <Typography
          className="text-box"
          variant="title-2-semibold"
        >
          {errorData.title}
        </Typography>
        <Typography
          className="text-box"
          variant="body-regular"
        >
          {errorData.subtitle}
        </Typography>
      </section>
      <Button
        className="bottom-button"
        icon={errorData.btnIcon}
        onClick={errorData.callback}
        style={formStyles}
      >
        {t(errorData.btnText)}
      </Button>
    </>
  );
};
