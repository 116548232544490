import { AbstractSubmitStrategy } from './AbstractSubmitStrategy';
import { formRequestBody } from '../payUtils';
import { PaymentProcessStrategyHandleProps } from './models';

export class PaymentProcessStrategy implements AbstractSubmitStrategy {
  public handle(props: PaymentProcessStrategyHandleProps):void {
    const {
      payGroupId,
      fields,
      currency,
      amount,
      trigger,
      signal,
    } = props;

    const requestBody = formRequestBody({
      fields,
      currency,
      amount,
    });

    trigger({
      payGroupId,
      requestBody,
      signal,
    });
  }
}
