import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { FINGERPRINT } from 'store/constants';
import { softLocalStorageSet } from 'utils/storage';

const fpPromise = FingerprintJS.load();

export const setFingerprint:VoidFunction = async ():Promise<void> => {
  const fp = await fpPromise;
  const result = await fp.get();

  softLocalStorageSet(FINGERPRINT, result.visitorId);
};
