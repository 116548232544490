import { UIPaymentGroups, UIPayoutGroups } from 'store/formatters';

export const getUnavailableEventData = (availableMethods: UIPayoutGroups[] | UIPaymentGroups[]) => {
  const unavailableMethods: string[] = [];

  availableMethods.forEach((x) => {
    if (!x.enabled) unavailableMethods.push(x.displayName);
  });

  if (unavailableMethods.length) return unavailableMethods;
  return null;
};
