import { FC, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import {
  PayHubCashierContractsCashier3DSResultResponse,
  PayHubWidgetContractsSubmitPaymentResponse,
  PayHubWidgetContractsSubmitPayoutResponse,
} from 'store/models';
import { FlowTypes } from 'store/constants';
import { setHeadersNavIcon, setIsStatusPageMode, setIsSubmitted } from 'store/slices/global';
import { SubmitForm } from './SubmitForm';
import { StatusRedirect } from '../StatusRedirect';

interface SubmitFormWrapperProps {
  // eslint-disable-next-line max-len
  redirectForm?: PayHubWidgetContractsSubmitPaymentResponse | PayHubWidgetContractsSubmitPayoutResponse | PayHubCashierContractsCashier3DSResultResponse;
  paymentType: FlowTypes;
  isNativeDevice?: boolean;
  isAwait?: boolean;
  isLLA?: boolean;
}

// TODO: should be refactored (submit form should has a separate route path)
export const SubmitFormWrapper:FC<SubmitFormWrapperProps> = (props) => {
  const {
    redirectForm,
    paymentType,
    isNativeDevice,
    isAwait,
    isLLA,
  } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsSubmitted(true));

    if (redirectForm) {
      dispatch(setHeadersNavIcon('close'));
      dispatch(setIsStatusPageMode(true));
    }
  }, []);

  if (!redirectForm) {
    return null;
  }

  return (
    <>
      <SubmitForm
        redirectForm={redirectForm}
        paymentType={paymentType}
        isAwait={isAwait}
        isLLA={isLLA}
      />
      <StatusRedirect
        paymentType={paymentType}
        redirectForm={redirectForm}
        isNativeDevice={isNativeDevice}
      />
    </>
  );
};
