import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface AccountManagementState {
  blockDuration?: string;
  maxRequestCount?: number;
  requisiteId?: number | null;
}

const initialState: AccountManagementState = {
  blockDuration: '',
  maxRequestCount: 0,
  requisiteId: null,
};

const accountManagementSlice = createSlice({
  name: 'accountManagement',
  initialState,
  reducers: {
    setSendCodeInfo: (state, action: PayloadAction<AccountManagementState>) => ({
      ...state,
      ...action.payload,
    }),
    setRequisiteId: (state, action: PayloadAction<number | null>) => ({
      ...state,
      requisiteId: action.payload,
    }),
  },
});

export const maxRequestCountSelector = ({ accountManagement }:
  { accountManagement: AccountManagementState }) => accountManagement.maxRequestCount;

export const requisiteIdSelector = ({ accountManagement }:
  { accountManagement: AccountManagementState }) => accountManagement.requisiteId;

export const {
  setSendCodeInfo,
  setRequisiteId,
} = accountManagementSlice.actions;

export default accountManagementSlice.reducer;
