/* eslint-disable camelcase */
import { Dispatch } from '@reduxjs/toolkit';
import { setSubmitRedirectError } from 'store/slices/submitForm';
import { isValidJSON } from '../../../utils/validator';

interface CreatorProps {
  dispatch: Dispatch;
}

export const errorSubmitFormHandlerCreator = (
  creatorProps: CreatorProps,
) => async (
  json: string,
  stopConnection: () => Promise<void>,
) => {
  const {
    dispatch,
  } = creatorProps;
  const data = isValidJSON(json) ? JSON.parse(json) : {};

  const isSubmitRedirectHasError = data?.submitRedirectResult === 'failed';

  if (isSubmitRedirectHasError) {
    dispatch(setSubmitRedirectError());
    await stopConnection();
  }
};
