/* eslint-disable max-len */
const VisaIcon = () => (
  <svg
    width="24"
    height="8"
    viewBox="0 0 24 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.13112 0.0289853L5.97712 7.93472H3.92138L2.37254 1.73912C2.36357 1.58794 2.3167 1.44173 2.2365 1.31474C2.15631 1.18774 2.04552 1.0843 1.91493 1.01448C1.30905 0.690365 0.665574 0.446868 0 0.289853L0.0422411 0H3.34409C3.5644 0.0126121 3.77372 0.103159 3.93664 0.256326C4.09956 0.409492 4.20609 0.615878 4.23819 0.840573L5.05486 5.28257L7.07539 0L9.13112 0.0289853ZM17.1569 5.34054C17.1569 3.30432 14.4183 3.16664 14.4394 2.28259C14.4394 2.00723 14.6999 1.71013 15.2631 1.63767C15.9202 1.57381 16.5819 1.69399 17.1781 1.98549L17.516 0.347823C16.935 0.124049 16.3199 0.00871714 15.6996 0.00724631C13.7847 0.00724631 12.433 1.05796 12.4189 2.55795C12.4189 3.66664 13.3834 4.28982 14.1156 4.65939C14.8478 5.02895 15.1223 5.28257 15.1223 5.6159C15.1223 6.13763 14.5169 6.34053 13.9607 6.34053C13.2632 6.37136 12.5697 6.21662 11.9472 5.89126L11.5952 7.5869C12.284 7.86324 13.0173 8.00337 13.7565 7.99994C15.7982 7.99994 17.1288 6.96372 17.1358 5.35503L17.1569 5.34054ZM22.2048 7.94921H24L22.4512 0.0289853H20.7967C20.6127 0.0468651 20.4375 0.118404 20.2915 0.235205C20.1456 0.352007 20.0351 0.509223 19.973 0.688401L17.0584 7.93472H19.093L19.5013 6.61589H21.9935L22.2048 7.94921ZM20.0575 4.97098L21.0783 2.07245L21.6697 4.97098H20.0575ZM11.8909 0.0289853L10.2857 7.93472H8.34262L9.95483 0.0289853H11.8909Z"
      fill="#1A1F71"
    />
  </svg>
);

export default VisaIcon;
