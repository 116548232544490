/* eslint-disable max-len */
const PciDcc = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 66 44"
    fill="#fff"
    fillRule="evenodd"
    stroke="#000"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <use
      xlinkHref="#A"
      x=".5"
      y=".5"
    />
    <symbol
      id="A"
      overflow="visible"
    >
      <g
        stroke="none"
        fillRule="nonzero"
      >
        <path
          d="M64 26.66L14.442 42.213 0 2.9 52.027 0 64 26.66"
          fill="#02797e"
        />
        <path d="M26.538 18.761a5.47 5.47 0 0 1-.679 2.654 5.49 5.49 0 0 1-1.85 1.913c-.8.493-1.8.864-2.9 1.11s-2.345.37-3.765.37h-.987v5.06h-6.42v-16.85h7.53c2.84 0 5.06.494 6.665 1.42s2.407 2.407 2.407 4.32m-6.172.062c0-1.48-1.11-2.222-3.27-2.222h-.802v4.508h.74c1.05 0 1.913-.185 2.47-.555.555-.31.864-.926.864-1.728zM42.46 29.87a18.2 18.2 0 0 1-3.394.31c-1.8 0-3.394-.185-4.752-.617-1.42-.432-2.592-.987-3.58-1.728a7.21 7.21 0 0 1-2.222-2.716c-.494-1.11-.74-2.284-.74-3.58s.247-2.47.802-3.58c.564-1.072 1.34-2.017 2.284-2.777.987-.74 2.16-1.358 3.518-1.8s2.84-.617 4.444-.617c1.234 0 2.407.062 3.456.247 1.05.247 1.05.247 1.85.555v4.505a7.51 7.51 0 0 0-2.16-.864c-.802-.247-1.666-.37-2.53-.37-1.48 0-2.654.432-3.58 1.173-.926.802-1.358 1.913-1.358 3.456 0 .74.123 1.42.432 2.037.31.555.68 1.05 1.173 1.42a5.05 5.05 0 0 0 1.728.864c.68.185 1.358.31 2.098.31s1.543-.062 2.284-.247 1.543-.37 2.345-.74v4.258l-2.098.494m4.752-16.85h6.48v16.9h-6.48zm7.16-5.308c0 1.11-.37 2.037-1.11 2.654a4.23 4.23 0 0 1-2.777.987c-1.11 0-2.037-.31-2.777-.987-.74-.617-1.11-1.543-1.11-2.654 0-1.173.37-2.037 1.11-2.716s1.666-.987 2.777-.987 2.037.31 2.777.987c.74.617 1.11 1.543 1.11 2.716" />
      </g>
    </symbol>
  </svg>
);

export default PciDcc;
