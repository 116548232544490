import { createSlice } from '@reduxjs/toolkit';

export interface SubmitFormSliceState {
  isRedirectOk: boolean;
}

export const initialState: SubmitFormSliceState = {
  isRedirectOk: true,
};

const submitFormSlice = createSlice({
  name: 'submitForm',
  initialState,
  reducers: {
    setSubmitRedirectError(state) {
      return {
        isRedirectOk: false,
      };
    },
    resetSubmitRedirectError() {
      return {
        isRedirectOk: true,
      };
    },
  },
});

export const submitRedirectErrorSelector = ({
  submitForm,
}: { submitForm: SubmitFormSliceState }) => submitForm.isRedirectOk;

export const {
  setSubmitRedirectError,
  resetSubmitRedirectError,
} = submitFormSlice.actions;

export default submitFormSlice.reducer;
