import IntegrationCommunication from 'services/integration-communication';
import { NavigateFunction } from 'react-router-dom';
import { getTheme } from './common';
import { parseQuery } from './locactionAndUrls';

const prepareChildFrameParams = (params: string) => {
  const theme = getTheme();
  let preparedParameters = params;
  const themeExists = preparedParameters.includes('theme=');

  if (!themeExists) {
    preparedParameters = preparedParameters.concat(`&theme=${theme}`);
  }

  return preparedParameters;
};

export const listenChildFrame = (event: MessageEvent, navigate: NavigateFunction) => {
  const childFrame = document.querySelector('[title="innerCashier"]');
  if (!childFrame) {
    return;
  }

  const { data: { type, status, search } } = event;
  const parameters = prepareChildFrameParams(search);

  navigate({
    pathname: `/${type}/${type}-${status}`,
    search: parameters,
  });
};

export const setSessionIdFromParent = (event: MessageEvent) => {
  const { data } = event;

  if (data.sessionId) {
    const params = new URLSearchParams(window.location.search);
    params.set('sessionId', data.sessionId);
    window.location.search = params.toString();
  }
};

export const listenOuterMessages = () => {
  IntegrationCommunication.sendMessage({
    id: 'reInitCashier',
  });
};

export const widgetSessionCommunication = () => {
  const childFrame = document.querySelector('[title="innerCashier"]');
  if (!childFrame) {
    return;
  }
  const parentIframe = document.querySelector('iframe');
  const { search } = window.location;
  const parsedQuery = parseQuery(search);

  parentIframe?.contentWindow?.postMessage({
    id: 'setSessionIdToChildFrame',
    sessionId: parsedQuery?.sessionId,
  }, '*');
};
