/* eslint-disable camelcase */
import { PAGE_STATUS } from 'utils/constants';
import IntegrationCommunication from 'services/integration-communication';
import { FlowTypes } from 'store/constants';
import { PayHubCashierContractsPaymentSessionStateResponse } from '../../store/models';

const STATUSES_WITH_CLOSE_MSG = [
  PAGE_STATUS.success,
  PAGE_STATUS.processing,
];

export const hasCloseCashierMsg = (isInPopup: boolean, status: PAGE_STATUS) => isInPopup && (
  STATUSES_WITH_CLOSE_MSG.includes(status));

interface ReturnUrlFormer {
  success_url?: string;
  processing_url?: string;
  fail_url?: string;
  decodedUrl: string | boolean;
  merchant_url?: string | null;
  flowType: FlowTypes;
}

export const returnUrlFormer = (props: ReturnUrlFormer) => (status: PAGE_STATUS): string => {
  const {
    success_url,
    processing_url,
    fail_url,
    decodedUrl,
    merchant_url = '',
    flowType,
  } = props;
  const parsedDecodedUrl = typeof decodedUrl === 'string' ? decodedUrl : '/';
  const decodedUrlResult = parsedDecodedUrl === '/' && merchant_url ? merchant_url : parsedDecodedUrl;

  if (flowType === FlowTypes.withdrawal) {
    return processing_url || decodedUrlResult;
  }

  switch (status) {
    case PAGE_STATUS.success: {
      return success_url || decodedUrlResult;
    }

    case PAGE_STATUS.processing: {
      return processing_url || decodedUrlResult;
    }

    case PAGE_STATUS.error: {
      return fail_url || decodedUrlResult;
    }

    default: {
      return success_url || decodedUrlResult;
    }
  }
};

interface ClosePopUpStatusPageButtonHandlerProps {
  success_url?: string;
  processing_url?: string;
  fail_url?: string;
  status: PAGE_STATUS;
}
export const closePopUpStatusPageButtonHandler = (props: ClosePopUpStatusPageButtonHandlerProps) => {
  const {
    success_url,
    processing_url,
    fail_url,
    status,
  } = props;
  let redirectStatusUrl: string | undefined = '';

  if (success_url || processing_url || fail_url) {
    // eslint-disable-next-line default-case
    switch (status) {
      case PAGE_STATUS.success: {
        redirectStatusUrl = success_url;
        break;
      }

      case PAGE_STATUS.processing: {
        redirectStatusUrl = processing_url;
        break;
      }

      case PAGE_STATUS.error: {
        redirectStatusUrl = fail_url;
        break;
      }
    }
  }

  if (redirectStatusUrl) {
    IntegrationCommunication.sendMessage({
      id: 'redirectTo',
      value: redirectStatusUrl,
    });
  }

  IntegrationCommunication.sendMessage({
    id: 'closeCashier',
  });
};

export const redirectFrameOrOpenNewWindow = (url: string, merchantRedirectType?: string) => {
  if (
    typeof merchantRedirectType === 'string'
    && merchantRedirectType?.toLowerCase() === 'blank'
    && url
  ) {
    window.open(url, '_blank');
  } else if (url) {
    window.location.assign(url);
  }
};

const parseTimeout = (timeout?: string): number => {
  const parsed = parseInt(timeout || '0', 10);
  return Number.isNaN(parsed) ? 0 : parsed;
};

export const getCalculatedTimeoutByStatus = (
  status: PAGE_STATUS,
  paySessionState: PayHubCashierContractsPaymentSessionStateResponse,
  rtParamFromURL?: false | number,
) => {
  const defaultTimeout = 30000; // timer in TransactionProcessing

  const successTimeCount = (rtParamFromURL || parseTimeout(paySessionState.success_page_redirect_timeout)) * 1000;
  const processingTimeCount = (rtParamFromURL || parseTimeout(paySessionState.processing_page_redirect_timeout)) * 1000;
  const failTimeCount = (rtParamFromURL || parseTimeout(paySessionState.fail_page_redirect_timeout)) * 1000;

  const timeoutMap: Record<PAGE_STATUS, number> = {
    [PAGE_STATUS.success]: successTimeCount,
    [PAGE_STATUS.processing]: processingTimeCount,
    [PAGE_STATUS.error]: failTimeCount,
    [PAGE_STATUS.await]: processingTimeCount,
    [PAGE_STATUS['charity-success']]: successTimeCount,
  };

  return timeoutMap[status] || defaultTimeout;
};
