import { parseQuery } from 'utils';

export const getParamsForLLARequest = () => {
  try {
    const { href } = window.location;
    const queryArr = href.split('/');
    const [sessionId, search] = queryArr[queryArr.length - 1].split('?');
    const { exp, ...rest } = parseQuery(search) as { exp?: string };
    // mind that it's not ts, but C# ticks -> https://stackoverflow.com/a/7968483
    const currTicks = (new Date().getTime() * 10000) + 621355968000000000;

    const isExpired = currTicks > Number(exp);
    const convertedData = Object.entries(rest as {[key: string]: string})
      .reduce((acc, [key, value]) => ({
        ...acc,
        [key.toLowerCase()]: value,
      }), {});
    const { pares, cres, md } = convertedData as { pares: string; cres: string; md: string };
    const paymentParams = ({
      pares,
      cres,
      md,
    });

    return {
      sessionId,
      isExpired,
      paymentParams,
    };
  } catch (error) {
    return false;
  }
};
