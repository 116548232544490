import { Dispatch } from '@reduxjs/toolkit';
import { setCarouselMethods, setCarouselSelectedMethodId, setCarouselViewOn } from 'store/slices/carousel';
import { UIPaymentGroups, UIPayoutGroups } from 'store/formatters';
import { PayHubDomainSessionsEnumsPlatformType } from 'store/models';
import { FlowTypes } from 'store/constants';
import { NavigateFunction } from 'react-router-dom';
import { selectWithdrawalMethodHandler } from '../Withdrawal/utils';

interface ShowCarouselAndSetInitDataProps {
  dispatch: Dispatch;
  methodsToSet: (UIPaymentGroups | UIPayoutGroups)[];
  selectedMethodId: number;
}

interface IsGoodForCarouselProps {
  method: UIPaymentGroups | UIPayoutGroups;
  methods: (UIPaymentGroups | UIPayoutGroups)[];
  initCarouselMethods?: (UIPaymentGroups | UIPayoutGroups)[];
}

export interface UseCarouselProps {
  methods: (UIPaymentGroups | UIPayoutGroups)[];
  initCarouselMethods?: (UIPaymentGroups | UIPayoutGroups)[];
  methodToRedirect?: UIPaymentGroups | UIPayoutGroups;
  methodIdToRedirect?: number | null;
  isCarouselViewChanged?: boolean;
  isInitialCarouselLoad?: boolean;
  platform?: PayHubDomainSessionsEnumsPlatformType;
  flowType: FlowTypes;
  skip?: boolean;
}

export const showCarouselAndSetInitData = (props: ShowCarouselAndSetInitDataProps) => {
  const {
    dispatch,
    methodsToSet,
    selectedMethodId,
  } = props;

  dispatch(setCarouselViewOn());
  dispatch(setCarouselMethods(methodsToSet));
  dispatch(setCarouselSelectedMethodId(selectedMethodId));
};

const processDepositCarousel = (props: UseCarouselProps, dispatch: Dispatch) => {
  const {
    initCarouselMethods,
    methodToRedirect,
    methodIdToRedirect,
  } = props;

  const carouselMethodsToSet = methodToRedirect && (
    initCarouselMethods && initCarouselMethods.findIndex(({ id }) => id === methodIdToRedirect) === -1)
    ? [
      methodToRedirect,
      ...initCarouselMethods,
    ]
    : initCarouselMethods;

  if (carouselMethodsToSet && carouselMethodsToSet.length > 1) {
    showCarouselAndSetInitData({
      dispatch,
      methodsToSet: carouselMethodsToSet?.length > 5 ? carouselMethodsToSet.slice(0, 5) : carouselMethodsToSet,
      selectedMethodId: methodIdToRedirect || carouselMethodsToSet[0]?.id,
    });
  }
};

const processWithdrawalCarousel = (props: UseCarouselProps, dispatch: Dispatch, navigate: NavigateFunction) => {
  const {
    initCarouselMethods,
    platform,
  } = props;

  if (initCarouselMethods && initCarouselMethods.length > 1) {
    showCarouselAndSetInitData({
      dispatch,
      methodsToSet: initCarouselMethods?.length > 5 ? initCarouselMethods.slice(0, 5) : initCarouselMethods,
      selectedMethodId: initCarouselMethods[0]?.id,
    });

    selectWithdrawalMethodHandler({
      method: initCarouselMethods[0],
      platform,
      dispatch,
      navigate,
      flowType: FlowTypes.withdrawal,
    });
  }
};

export const processCarouselData = (
  flowType: FlowTypes,
  dispatch: Dispatch,
  navigate: NavigateFunction,
) => (props: UseCarouselProps) => (flowType === FlowTypes.deposit
  ? processDepositCarousel(props, dispatch)
  : processWithdrawalCarousel(props, dispatch, navigate));

const isPreselectedIfExist = (method: UIPaymentGroups | UIPayoutGroups) => {
  if ('preSelectedMethod' in method) {
    return method?.preSelectedMethod;
  }

  return true;
};

export const isGoodForCarousel = (props: IsGoodForCarouselProps) => {
  const {
    method,
    methods,
    initCarouselMethods,
  } = props;

  const isInCarouselList = initCarouselMethods && initCarouselMethods.findIndex((current) => current.id === method.id);
  const isInMethods = methods.findIndex((
    current,
  ) => (current.id === method.id)
    && current.enabled
    && isPreselectedIfExist(current)
    && current.last_used);

  return isInCarouselList !== -1 || isInMethods !== -1;
};

interface ErrorPageCarouselProcessProps extends UseCarouselProps {
  dispatch: Dispatch;
  navigate: NavigateFunction;
  method: UIPaymentGroups | UIPayoutGroups;
}

export const errorPageCarouselProcess = (props: ErrorPageCarouselProcessProps) => {
  const {
    method,
    methods,
    initCarouselMethods,
    flowType,
    dispatch,
    navigate,
  } = props;
  const process = processCarouselData(flowType, dispatch, navigate);
  const skipProcessCarousel = !isGoodForCarousel({
    method,
    methods,
    initCarouselMethods,
  }) || !(methods?.length > 1 && initCarouselMethods?.length);

  if (skipProcessCarousel) {
    return;
  }

  process(props);
};
