export enum FlowTypes {
  'deposit' = 'deposit',
  'withdrawal' = 'withdrawal',
}

export enum appChannelTypes {
  'ios' = 'IOS',
  'android' = 'ANDROID',
  'windows_phone' = 'WINDOWS_PHONE',
  'web' = 'WEB',
  'unknown' = 'UNKNOWN',
}

export enum UIThemeTypes {
  'modulor' = 'modulor',
}

export const SESSION_PROLONG_TIME = {
  L: 15 * 60 * 1000,
  S: 5 * 60 * 1000,
};

export const DEFAULT_EMPTY_CURRENCY_ALPHA3 = '';
export const FINGERPRINT = 'PH_fingerprint';

export type RedirectFlowType = 'redirect' | 'blank' | 'top' | 'parent';
export enum REDIRECT_FLOW_TYPES {
  redirect = '_self',
  blank = '_blank',
  top = '_top',
  parent = '_self',
}

export enum SUBMIT_FORM_ACTIONS {
  blank = 'blank',
  redirect = 'redirect',
  result = 'result',
}

export const SELECTED_METHOD_ID = 'phSelectedMethodId';
export const LAST_USED_METHOD_ID = 'phLastUsedMethodId';
export const ALT_METHODS = 'phAltMethods';

export enum RequisiteTypes {
  email = 'email',
  phone = 'phone',
}

export const noMethodsError = {
  key: 'key',
  style: 'error',
  type: 'information_block',
  value: 'CASHIER.PH2.NO_METHODS_MESSAGE',
};
export const UNDEFINED_VALUE = undefined;
export const PREFER_CACHE_VALUE = false;

export const SUBMIT_REDIRECT_ERROR_TIME = 5000;
