import cardValidator from 'card-validator';
import { FieldValues, UseFormSetError } from 'react-hook-form';

type SubmitValidationOpts = {
    shouldValidateCard?: boolean;
    cardFieldName?: string;
    amount?: number;
  };

export const hasValidationError = (
  fields: FieldValues,
  setError: UseFormSetError<FieldValues>,
  opts: SubmitValidationOpts = {},
): boolean => {
  const {
    shouldValidateCard,
    cardFieldName,
    amount,
  } = opts;

  if (shouldValidateCard && cardFieldName && fields[cardFieldName] && (
    !cardValidator.number(fields[cardFieldName]).isValid
  )) {
    setError(`${cardFieldName}`, {
      type: 'manual',
    });
    return true;
  }

  if (!amount) {
    setError('amount', {
      type: 'manual',
    });
    return true;
  }

  return false;
};
