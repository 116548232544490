export const isValidRegExp = (regExpString: string): boolean => {
  try {
    RegExp(regExpString);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidJSON = (JSONString: string): boolean => {
  try {
    JSON.parse(JSONString);
    return true;
  } catch (e) {
    return false;
  }
};
